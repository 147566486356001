import React,{useState} from 'react';
import EmployAside from '../aside';
import DashboardMessage from './dashboard-message';

const BoitesMessage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <DashboardMessage setIsOpenSidebar={setIsOpenSidebar}/>
    </div>    
  );
};

export default BoitesMessage;