import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProject } from "../../../redux/packageSlice";
import { FaApple, FaGoogle, FaFacebook, FaTwitter, FaAmazon } from "react-icons/fa";

const CandidateV3Area = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
  // console.log('Projet sélectionné :', projectData);

  const [selectedFontTitle, setSelectedFontTitle] = useState(projectData.fontTitle || "");
  const [selectedTitle, setSelectedTitle] = useState(projectData.title || "");
  const [selectedSlogan, setSelectedSlogan] = useState(projectData.slogan || "");
  const [selectedFontSlogan, setSelectedFontSlogan] = useState(projectData.fontSlogan || "");
  const [selectedColorForeground, setSelectedColorForeground] = useState(projectData.colorForeground || "");
  const [selectedColorBackground, setSelectedColorBackground] = useState(projectData.colorBackground || "");

  const [selectedIcon, setSelectedIcon] = useState(projectData.icon || "");

  useEffect(() => {
    setSelectedTitle(projectData.title || '');
    setSelectedSlogan(projectData.slogan || '');
    setSelectedFontTitle(projectData.fontTitle || '');
    setSelectedFontSlogan(projectData.fontSlogan || '');
    setSelectedIcon(projectData.icon || '');
    setSelectedColorForeground(projectData.colorForeground || '');
    setSelectedColorBackground(projectData.colorBackground || '');
}, [projectData]);


  const icons = [
    { name: "fa-apple", component: <FaApple /> },
    { name: "fa-google", component: <FaGoogle /> },
    { name: "fa-facebook", component: <FaFacebook /> },
    { name: "fa-twitter", component: <FaTwitter /> },
    { name: "fa-amazon", component: <FaAmazon /> },
  ];

  const colors = [
    { name: "Rouge", code: "#FF5733" },
    { name: "Vert", code: "#33FF57" },
    { name: "Bleu", code: "#3357FF" },
    { name: "Rose", code: "#FF33A1" },
    { name: "Jaune", code: "#FFD700" },
    { name: "Violet", code: "#800080" },
    { name: "Cyan", code: "#00FFFF" },
    { name: "Orange", code: "#FFA500" },
    { name: "Vert foncé", code: "#008000" },
    { name: "Bleu foncé", code: "#0000FF" },
  ];
  


  const handleForegroundColorClick = (color) => {
    setSelectedColorForeground(color.name);
   
};

const handleBackgroundColorClick = (color) => {
    setSelectedColorBackground(color.name);

};




  const handleIconClick = (icon) => {
    setSelectedIcon(icon.name);
    console.log(icon.name)
   
};
const handleTitleChange = (e) => {
  setSelectedTitle(e.target.value);
};

const handleSloganChange = (e) => {
  setSelectedSlogan(e.target.value);
};

const handleFontTitleChange = (e) => {
  setSelectedFontTitle(e.target.value);
};

const handleFontSloganChange = (e) => {
  setSelectedFontSlogan(e.target.value);
};
const handleValidate = () => {
  dispatch(setProject(
    { projectId,
      data: {
        logo: {
          logoToCreate: {
            fontTitle: selectedFontTitle,
            title: selectedTitle,
            slogan: selectedSlogan,
            fontSlogan: selectedFontSlogan,
            colorForeground: selectedColorForeground,
            colorBackground: selectedColorBackground,
            icon: selectedIcon,            }
        }
    }
  })) 
   
};


  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "135px", paddingBottom: "30px" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                  CRÉATION PACKAGE COMMERCIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to={`/package-com4N/${projectId}`}>
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">5/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">
              Sélectionnez une icône, indiquez un nom et un slogan (optionnel)
            </h4>
          </div>
          <div className="row mt-5">
            <div className="col-12 mb-4">
              <div className="card p-4 border rounded shadow-sm">
                <div className="card-body">
                  <h5 className="card-title">Catalogue d’icônes (avec les prix)</h5>
                  <div className="d-flex justify-content-around">
                    {icons.map((icon) => (
                      <div
                      key={icon.name}
                      onClick={() => handleIconClick(icon)}
                      style={{
                          cursor: "pointer",
                          border: selectedIcon === icon.name ? "2px solid green" : "none",
                      }}
                     >
                      {icon.component}
                  </div>
                  
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-4">
              <div className="card p-4 border rounded shadow-sm">
                <h5 className="card-title">Couleur de premier plan</h5>
                <div className="d-flex justify-content-around">
                  {colors.map((color) => (
                    <button
                      key={color.name}
                      className="color-swatch"
                      style={{
                        backgroundColor: color.code,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border:
                          selectedColorForeground === color.name
                            ? "3px solid black"
                            : "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleForegroundColorClick(color)}
                    />
                  ))}
                </div>
                <p className="mt-3">
                  Couleur sélectionnée : <strong>{selectedColorForeground || "Aucune"}</strong>
                </p>

              
             </div>
            </div>

            <div className="col-12 mb-4">
              <div className="card p-4 border rounded shadow-sm">
                <h5 className="card-title">Couleur d'arrière-plan</h5>
                <div className="d-flex justify-content-around">
                  {colors.map((color) => (
                    <button
                      key={color.name}
                      className="color-swatch"
                      style={{
                        backgroundColor: color.code,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border:
                          selectedColorBackground === color.name
                            ? "3px solid black"
                            : "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleBackgroundColorClick(color)}
                    />
                  ))}
                </div>
                <p className="mt-3">
                  Couleur sélectionnée : <strong>{selectedColorBackground || "Aucune"}</strong>
                </p>
              </div>
            </div>

            <div className="col-12 mb-4">
              <div className="input-group-meta position-relative mb-25">
                <label htmlFor="nom">Nom</label>
                <input
                  type="text"
                  id="nom"
                  placeholder="Entrez un nom"
                  className="form-control"
                  value={selectedTitle} 
                  onChange={handleTitleChange} 
                />
             </div>
            </div>
            <div className="col-12 mb-4">
              <div className="input-group-meta position-relative mb-25">
                <label htmlFor="slogan">Slogan (optionnel)</label>
                <input
                  type="text"
                  id="slogan"
                  placeholder="Entrez un slogan"
                  className="form-control"
                  value={selectedSlogan} 
                  onChange={handleSloganChange} 
                />
              </div>
            </div>

            <div className="col-12 mb-4">
              <label htmlFor="font-title-select" className="form-label">Police de titre</label>
              <select
                id="font-title-select"
                className="form-select"
                value={selectedFontTitle}
                onChange={handleFontTitleChange}
              >
                <option value="">-- Sélectionnez une police --</option>
                <option value="Arial">Arial</option>
                <option value="Georgia">Georgia</option>
                <option value="Tahoma">Tahoma</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
              </select>
            </div>
            {selectedFontTitle && ( 
                            <div className="col-12 mt-4">
                                <div className="example-text-section p-4 border rounded" style={{ fontFamily: selectedFontTitle }}>
                                <h5 className="fw-600 mb-3">Donnez un style unique à votre entreprise !</h5>
                                </div>
                            </div>
                            )}               
                        
            <div className="col-12 mb-4">
              <label htmlFor="font-slogan-select" className="form-label">Police de slogan</label>
              <select
                id="font-slogan-select"
                className="form-select"
                value={selectedFontSlogan}
                onChange={handleFontSloganChange}
              >
                <option value="">-- Sélectionnez une police --</option>
                <option value="Arial">Arial</option>
                <option value="Georgia">Georgia</option>
                <option value="Tahoma">Tahoma</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
              </select>
            </div>
            {selectedFontSlogan && ( 
                            <div className="col-12 mt-4">
                                <div className="example-text-section p-4 border rounded" style={{ fontFamily: selectedFontSlogan }}>
                                <h5 className="fw-600 mb-3">Donnez un style unique à votre entreprise !</h5>
                                </div>
                            </div>
                            )}     
                            
                        <div className="col-12">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com4N2/${projectId}`} className="d-block" onClick={handleValidate}>
                                    Validez
                                </Link>
                            </button>
                        </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
