import React,{useState} from 'react';
import EmployAside from '../aside';
import EmployProfileArea from './profile-area';

const EmployDashboardProfilePage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    

    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <EmployProfileArea setIsOpenSidebar={setIsOpenSidebar}/>
    </div>    
  );
};

export default EmployDashboardProfilePage;