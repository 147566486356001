import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { generateText } from "../../../api/Services/webPackageService";
import { useDispatch } from "react-redux";
import { setProject, setServices } from "../../../redux/packageSlice";

const CandidateV3Area = ({ style_2 }) => {
    const [textareaValue, setTextareaValue] = useState("");
    const [generatedText, setGeneratedText] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { projectId } = useParams();
   

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const dispatch = useDispatch();

    const handleGenerateClick = async () => {
        const keywords = textareaValue.split(",").map((word) => word.trim());
        setLoading(true);
        setError(null);
      
        try {
          const text = await generateText(keywords);
          setGeneratedText(text);
      
          dispatch(
            setProject({
              projectId,
              data: {
                website: {
                  services: {
                    text: text, 
                    items: keywords 
                  }
                }
              }
            })
          );
          

        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);     
        }
      };
      

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="d-flex align-items-center justify-content-center">
                            <Link to={`/package-com8/${projectId}`}>
                                <button type="button" className="btn btn-success">
                                    {"< Précédent"}
                                </button>
                            </Link>
                            <span className="mx-3">11/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">
                            Sélectionnez dans la liste déroulante des mots-clés concernant votre
                            activité. Vous pouvez également ajouter des mots qui n’y figurent pas.
                        </h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <textarea
                                className="form-control"
                                rows={3}
                                value={textareaValue}
                                onChange={handleTextareaChange}
                                placeholder="Ajoutez vos propres mots-clés ici, séparés par des virgules"
                            ></textarea>
                        </div>
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn-eleven fw-500 tran3s d-block mt-20"
                                onClick={handleGenerateClick}
                                disabled={loading}
                            >
                                {loading ? "Génération en cours..." : "Générez"}
                            </button>
                        </div>
                        {error && (
                            <div className="col-12 mt-4">
                                <div className="alert alert-danger">{error}</div>
                            </div>
                        )}
                        {generatedText && (
                            <div className="col-12 mt-4">
                                <div className="generated-text-section p-4 border rounded">
                                    <h5 className="fw-600 mb-3">Text généré</h5>
                                    <p>{generatedText}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                            <Link to={`/package-com9/${projectId}`} className="d-block">
                                Validez
                            </Link>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
