import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { getWebpackageOrders } from "../../../../api/Services/webPackageService";
import { getDMSDocuments } from "../../../../api/Services/gedService";
import { moveAttachmentToDMS } from "../../../../api/Services/emailService";
import { FaFolder } from "react-icons/fa";
import Select from "react-select";

const DeplacerDocumentVersDMS = ({ showDeplacer, handleCloseDeplacer, itemId, adressEmail, attachmentId }) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [newParentDocumentId, setNewParentDocumentId] = useState(null);
    const [loadingProjects, setLoadingProjects] = useState(true);
    const [loadingFolders, setLoadingFolders] = useState(true);
    const [error, setError] = useState(null);
    const [folders, setFolders] = useState([]);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await getWebpackageOrders();
                const projectItems = response?.data?.getWebpackageOrders?.items || [];
                setProjects(projectItems.map((project) => ({
                    value: project.projectId,
                    label: project.projectName,
                })));
            } catch (err) {
                console.error("Erreur lors du chargement des projets :", err);
            } finally {
                setLoadingProjects(false);
            }
        };
        fetchProjects();
    }, []);

    const handleProjectChange = (selectedOption) => {
      setSelectedProject(selectedOption);
      setNewParentDocumentId(null); 
      fetchFolders(selectedOption.value); 
  };
  

    const fetchFolders = async (projectId) => {
      setLoadingFolders(true); 
      try {
          const fetchAllFolders = async (parentId) => {
              const response = await getDMSDocuments(projectId, parentId, 100, null);
              const items = response?.items || []; 
              
              const folders = await Promise.all(
                  items
                      .filter((item) => item.documentType === "FOLDER") 
                      .map(async (item) => ({
                          name: item.documentName,
                          type: item.documentType,
                          id: item.documentId,
                          subfolders: await fetchAllFolders(item.documentId), 
                      }))
              );
              return folders; 
          };
  
          const structuredFolders = await fetchAllFolders("root");
          setFolders(structuredFolders); 
          console.log("Dossiers hiérarchiques :", structuredFolders); 
      } catch (error) {
          console.error("Erreur lors de la récupération des documents :", error);
          setError("Impossible de charger les dossiers. Veuillez réessayer."); 
      } finally {
          setLoadingFolders(false);
      }
  };
  
  
  

    const renderFolderOptions = (folders, level = 0) => {
        const options = [];
        folders.forEach((folder) => {
            if (folder.type === "FOLDER") {
                options.push({
                    value: folder.id,
                    label: (
                        <div style={{ display: "flex", alignItems: "center", marginLeft: level * 10 }}>
                            <FaFolder style={{ marginRight: "5px" }} />
                            {folder.name}
                        </div>
                    ),
                });
            }
            if (folder.subfolders && folder.subfolders.length > 0) {
                options.push(...renderFolderOptions(folder.subfolders, level + 1));
            }
        });
        return options;
    };

    const handleMove = async () => {
        if (!newParentDocumentId) {
            setError("Veuillez sélectionner un dossier de destination.");
            return;
        }
        try {
            await moveAttachmentToDMS(adressEmail, selectedProject.value, newParentDocumentId, itemId, attachmentId);
            handleCloseDeplacer();
            
        } catch (err) {
            setError("Une erreur est survenue lors du déplacement.");
        }
    };

    const handleChange = (selectedOption) => {
        setNewParentDocumentId(selectedOption ? selectedOption.value : "");
    };

    return (
        <Modal show={showDeplacer} onHide={handleCloseDeplacer} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Déplacer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <div className="alert alert-danger">{error}</div>}
                <Form.Group controlId="projectSelect">
                    <Form.Label>Sélectionnez un projet de destination</Form.Label>
                    <Select
                        options={projects}
                        value={selectedProject}
                        onChange={handleProjectChange}
                        isLoading={loadingProjects}
                        placeholder="Choisissez un projet"
                    />
                </Form.Group>
                <Form.Group controlId="folderSelect">
                    <Form.Label>Sélectionnez un dossier de destination</Form.Label>
                    {loadingFolders ? (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner animation="border" role="status" size="sm" />
                            <span style={{ marginLeft: "10px" }}>Chargement des dossiers...</span>
                        </div>
                    ) : (
                        <Select
                            options={renderFolderOptions(folders)}
                            value={renderFolderOptions(folders).find((opt) => opt.value === newParentDocumentId)}
                            onChange={handleChange}
                            isDisabled={loadingFolders}
                            placeholder="Choisissez un dossier"
                        />
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeplacer}>
                    Annuler
                </Button>
                <Button
                    variant="success"
                    onClick={handleMove}
                    disabled={!newParentDocumentId || loadingFolders}
                >
                    Déplacer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeplacerDocumentVersDMS;
