import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { partageDocument } from "../../../../api/Services/gedService";

const PartageDocument = ({ showPartage, handleClosePartage, projectId, documentIds }) => {
  const [emailAddresses, setEmailAddresses] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  console.log(documentIds);

  const handleShare = async () => {
    setLoading(true);
    setError(null);
    try {
      const destinationEmailAddresses = emailAddresses.split(",").map((email) => email.trim());
      const response=await partageDocument(projectId, documentIds, destinationEmailAddresses);
      console.log('response',response);
      console.log("Partage réussi");
      handleClosePartage();
    } catch (err) {
      console.error("Erreur de partage :", err);
      setError("Échec du partage. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={showPartage} onHide={handleClosePartage}>
      <Modal.Header closeButton>
        <Modal.Title>Partager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form.Group>
          <Form.Label>Adresses e-mail (séparées par des virgules)</Form.Label>
          <Form.Control
            type="text"
            placeholder="exemple@domaine.com, test@domaine.com"
            value={emailAddresses}
            onChange={(e) => setEmailAddresses(e.target.value)}
            disabled={loading}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosePartage} disabled={loading}>
          Annuler
        </Button>
        <Button variant="success" onClick={handleShare} disabled={loading || !emailAddresses.trim()}>
          {loading ? "Partage..." : "Partager"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PartageDocument;
