import axiosInstance from '../axiosConfig';

export const getUserInfo = async () => {


    try {
        const response = await axiosInstance.post('/graphql', {
            query: `
                query getPersonalUserAccountInfo {
                getPersonalUserAccountInfo {
                        email
                        name
                        phoneNumber
                        firstName
                }
            }
            `
           
        });
        return response.data.data.getPersonalUserAccountInfo;
    } catch (error) {
        console.error("Erreur lors de l'appel GraphQL :", error);
        throw error; 
    }
};
