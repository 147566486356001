import React, { useEffect, useState } from "react";
import DashboardHeader from "../header";
import { getUserInfo } from "../../../../api/Services/userService";
import { ClipLoader } from "react-spinners";

const EmployProfileArea = ({ setIsOpenSidebar }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getUserInfo();
        if (response) {
          setUserInfo({
            firstName: response.firstName || "",
            name: response.name || "",
            email: response.email || "",
            phoneNumber: response.phoneNumber || "",
          });
        }
      } catch (err) {
        console.error(
          "Erreur lors de la récupération des informations utilisateur :",
          err
        );
        setError("Impossible de récupérer les informations utilisateur.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h3 className="d-flex flex-column align-items-center">
          Mes informations personnelles
        </h3>

        <div className="bg-white card-box border-20 p-4">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "150px" }}
            >
              <ClipLoader size={50} color="#19AF78" />
            </div>
          ) : error ? (
            <div className="text-center">
              <p className="text-danger">{error}</p>
            </div>
          ) : userInfo ? (
            <form>
              <div className="row mb-4">
                <div className="col-md-6 mb-4">
                  <label htmlFor="firstName">Mon prénom</label>
                  <input
                    type="text"
                    className="form-control bg-light"
                    id="firstName"
                    value={userInfo.firstName}
                    placeholder="Prénom"
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label htmlFor="name">Mon nom</label>
                  <input
                    type="text"
                    className="form-control bg-light"
                    id="name"
                    value={userInfo.name}
                    placeholder="Nom"
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label htmlFor="email">Mon adresse mail</label>
                  <input
                    type="email"
                    className="form-control bg-light"
                    id="email"
                    value={userInfo.email}
                    placeholder="Email"
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label htmlFor="phoneNumber">Mon numéro de téléphone</label>
                  <input
                    type="tel"
                    className="form-control bg-light"
                    id="phoneNumber"
                    value={userInfo.phoneNumber}
                    placeholder="Téléphone"
                    readOnly
                  />
                </div>
              </div>
            </form>
          ) : (
            <div className="text-center w-100">
              <p>Aucune information utilisateur disponible.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployProfileArea;
