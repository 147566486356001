import React, { useState } from 'react';
import { useNavigate ,useLocation } from 'react-router-dom';

const Create_soc2 = () => {
  const navigate = useNavigate();
  const [isDomiciliationSelected, setIsDomiciliationSelected] = useState(false);
  const location= useLocation();
  const selectedService = location.state?.selectedService || 'Minimal'; 
  console.log(selectedService);
  const handleAddDomiciliation = () => {
    setIsDomiciliationSelected(true);
    console.log(isDomiciliationSelected);

    navigate('/create_soc3', { state: { isDomiciliationSelected: true ,selectedService} });
  };
  
  const handleContinueWithoutDomiciliation = () => {
    setIsDomiciliationSelected(false);
    navigate('/create_soc3', { state: { isDomiciliationSelected: false ,selectedService } });
  };
  

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION D'XXXXX</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-center mb-4">
          <h2 className="fw-600">
            Ajoutez d’autres services Pharos pour une commande plus complète
          </h2>
          <div className="d-flex align-items-center justify-content-center">
            <button type="button" className="btn btn-success">
              {"< Précédent"}
            </button>
            <span className="mx-3">2/4</span>
          </div>
        </div>
        <div className="title-one text-center mb-30 lg-mb-10">
          <h3 className="fw-600">Domiciliation avec Pharos Legal xx € HT</h3>
          <p className="fw-600 mt-4">
            Afin de ne pas utiliser votre propre adresse personnelle<br />
            pour la domiciliation de votre future société, Pharos Legal vous propose<br />
            des adresses partenaires pour plus de sécurité.
          </p>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <button
              type="button"
              className="btn-eleven fw-500 tran3s d-block mt-2"
              onClick={handleAddDomiciliation}
            >
              Ajouter DOMICILIATION et poursuivre la commande →
            </button>
          </div>
          <div className="col-12" style={{ textAlign: 'center', marginTop: '5px' }}>
            <button
              type="button"
              className="btn-eleven fw-500 tran3s"
              onClick={handleContinueWithoutDomiciliation}
            >
              Poursuivre la commande →
            </button>
          </div>
        </div>
        {/* <div className="mt-4">
          <p>
            <strong>État actuel :</strong>{" "}
            {isDomiciliationSelected
              ? "Domiciliation sélectionnée"
              : "Aucune domiciliation sélectionnée"}
          </p>
        </div> */}
      </div>
    </>
  );
};

export default Create_soc2;
