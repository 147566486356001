const initialState = {
  projets: {}, // Les projets seront stockés sous forme d'objet avec projectId comme clé
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROJET':
      return {
        ...state,
        projets: {
          ...state.projets,
          [action.payload.id]: action.payload, // Utiliser projectId comme clé
        },
      };
    default:
      return state;
  }
};

export const setProjet = (projet) => ({ type: 'SET_PROJET', payload: projet });

export default invoiceReducer;
