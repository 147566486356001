import axios from "axios";
import axiosInstance from "../axiosConfig";


export const getDMSDocuments = async (projectId, parentDocumentId,limit, nextToken ) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
         query getDMSDocuments($projectId: String!, $parentDocumentId: String!, $limit: Int, $nextToken: String) {
            getDMSDocuments(projectId: $projectId, parentDocumentId: $parentDocumentId, limit: $limit, nextToken: $nextToken) {
                    items {
                        documentName
                        documentId
                        documentType
                        documentSize
                        documentExtension
                        creationDate
                        updateDate
                        parentDocumentId
                    }
                    nextToken
            }
        }
        `,
        variables: { projectId, parentDocumentId,limit,nextToken},
      });
      return response.data?.data?.getDMSDocuments;
    } catch (error) {
      console.error('Erreur lors de la creation de dossier:', error);
      throw error;
    }
  };

export const createFoler = async (projectId, parentDocumentId,folderName) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
          mutation createDMSFolder($projectId: String!, $parentDocumentId: String!, $folderName: String!) {
                createDMSFolder(projectId: $projectId, parentDocumentId: $parentDocumentId, folderName: $folderName)
            }
        `,
        variables: { projectId, parentDocumentId,folderName },
      });
      return response.data.data.createDMSFolder;
    } catch (error) {
      console.error('Erreur lors de la creation de dossier:', error);
      throw error;
    }
  };
  /* requete uploadfile*/


export const signedUploadUrl = async (projectId, parentDocumentId, fileName) => {
  try {
    const response = await axiosInstance.post("/graphql", {
      query: `
        mutation getDMSPreSignedUploadUrl($projectId: String!, $parentDocumentId: String!, $fileName: String!) {
          getDMSPreSignedUploadUrl(projectId: $projectId, parentDocumentId: $parentDocumentId, fileName: $fileName) {
            url
            fields {
              key
              xAmzAlgorithm
              xAmzCredential
              xAmzDate
              xAmzSecurityToken
              policy
              xAmzSignature
            }
          }
        }
      `,
      variables: { projectId, parentDocumentId, fileName },
    });

    // Récupérer les informations de l'URL et les champs
    return response.data.data.getDMSPreSignedUploadUrl;
  } catch (error) {
    console.error("Erreur lors de la génération de l'URL pré-signée :", error);
    throw error;
  }
};

export const uploadFileToS3 = async (projectId, parentFolderId, file) => {
  try {
    const data = await signedUploadUrl(projectId, parentFolderId, file.name);

    const creds = {
      url: data.url,
      key: data.fields.key,
      xAmzAlgorithm: data.fields.xAmzAlgorithm,
      xAmzCredential: data.fields.xAmzCredential,
      xAmzDate: data.fields.xAmzDate,
      xAmzSecurityToken: data.fields.xAmzSecurityToken,
      policy: data.fields.policy,
      xAmzSignature: data.fields.xAmzSignature,
    };

    const formData = new FormData();
    formData.append("key", creds['key']);
    formData.append("policy", creds['policy']);
    formData.append("x-amz-algorithm", creds['xAmzAlgorithm']);
    formData.append("x-amz-credential", creds['xAmzCredential']);
    formData.append("x-amz-date", creds['xAmzDate']);
    formData.append("x-amz-security-token", creds['xAmzSecurityToken']);
    formData.append("x-amz-signature", creds['xAmzSignature']);
    formData.append("file", file);

    const response = await axios.post(creds['url'], formData);

    if (response.status === 204) {
      console.log("Fichier chargé avec succès !");
      return true;
    } else {
      throw new Error('Upload failed with status: ' + response.status);
    }
  } catch (error) {
    console.error("Erreur lors du chargement du fichier :", error);
    throw error;
  }
};

  
  /*requete searchDoument*/
  export const fetchSearchDocument = async (projectId, keyWord) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
        query searchDMSDocument($projectId: String!, $keyWord: String!, $nextToken: String) {
        searchDMSDocument(projectId: $projectId, keyWord: $keyWord, nextToken: $nextToken) {
                items {
                    documentName
                    documentId
                    documentType
                    documentSize
                    documentExtension
                    creationDate
                    updateDate
                    parentDocumentId
                }
                nextToken
        }
    }
        `,
        variables: { projectId, keyWord},
      });
      return response.data.data.searchDMSDocument;
    } catch (error) {
      console.error('Erreur lors de la chrgement de fichier:', error);
      throw error;
    }
  };

  /* suprimer Document*/
  export const deleteDocument = async (projectId, documentId) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
              mutation deleteDMSDocument($projectId: String!, $documentId: String!) {
            deleteDMSDocument(projectId: $projectId, documentId: $documentId)
        }
        `,
        variables: { projectId, documentId},
      });
      return response.data.data.deleteDMSDocument;
    } catch (error) {
      console.error('Erreur lors de la supression de fichier:', error);
      throw error;
    }
  };

  /* renomer doument*/
  export const downloadDocument = async (projectId, documentId) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
             mutation getDMSFilePreSignedDownloadUrl($projectId: String!, $documentId: String!) {
              getDMSFilePreSignedDownloadUrl(projectId: $projectId, documentId: $documentId) {
                      url
              }
          }
        `,
        variables: { projectId, documentId},
      });
      return response.data.data.getDMSFilePreSignedDownloadUrl;
    } catch (error) {
      console.error('Erreur lors de la chrgement de fichier:', error);
      throw error;
    }
  };

  /*trash document*/

  export const getDMSTrashDocuments = async (projectId, limit, nextToken) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
          query getDMSDocumentsInTrash($projectId: String!, $limit: Int, $nextToken: String) {
            getDMSDocumentsInTrash(projectId: $projectId, limit: $limit, nextToken: $nextToken) {
              items {
                documentId
                documentName
                documentType
              }
              nextToken
            }
          }
        `,
        variables: { projectId, limit, nextToken },
      });
      return response.data.data.getDMSDocumentsInTrash;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  };
  
  // paratge files

  export const partageDocument = async (projectId, documentIds,destinationEmailAddresses) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
        mutation triggerShareDMSDocuments($projectId: String!, $documentIds: [String!]!, $destinationEmailAddresses: [AWSEmail!]!) {
            triggerShareDMSDocuments(projectId: $projectId, documentIds: $documentIds, destinationEmailAddresses: $destinationEmailAddresses)
        }
        `,
        variables: { projectId, documentIds,destinationEmailAddresses },
      });
      console.log({ projectId, documentIds,destinationEmailAddresses });

      console.log(response.data.data.triggerShareDMSDocuments);
      return response.data.data.triggerShareDMSDocuments;
    } catch (error) {
      console.error('Erreur lors de partage  :', error);
      throw error;
    }
  };

  // deplacerfile 

   export const moveDocument = async (projectId, documentId,newParentDocumentId) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
             mutation moveDMSDocument(
                $projectId: String!, $documentId: String!, $newParentDocumentId: String!) {
                moveDMSDocument(projectId: $projectId, documentId: $documentId, 
                    newParentDocumentId: $newParentDocumentId)
            }
        `,
        variables: { projectId, documentId,newParentDocumentId },
      });
      return response.data.data.moveDMSDocument;
    } catch (error) {
      console.error('Erreur lors de deplcement  :', error);
      throw error;
    }
  };

  //renamefile
  export const renameDocument = async (projectId, documentId,newDocumentName) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
              mutation renameDMSDocument(
                $projectId: String!, $documentId: String!, $newDocumentName: String!) {
                renameDMSDocument(projectId: $projectId, documentId: $documentId, 
                    newDocumentName: $newDocumentName)
            }
        `,
        variables: { projectId, documentId,newDocumentName },
      });
      return response.data.data.renameDMSDocument;
    } catch (error) {
      console.error('Erreur lors de deplcement  :', error);
      throw error;
    }
  };

//copy document
export const copyDocument = async (projectId, documentId) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
            mutation copyDMSDocument(
                $projectId: String!, $documentId: String!) {
                copyDMSDocument(projectId: $projectId, documentId: $documentId)
            }
      `,
      variables: { projectId, documentId },
    });
    return response.data.data.copyDMSDocument;
  } catch (error) {
    console.error('Erreur lors de deplcement  :', error);
    throw error;
  }
};


