import axiosInstance from '../axiosConfig';

export const getAccountancyProjectConfigs = async (limit,nextToken) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
                query getAccountancyProjectConfigs($limit: Int, $nextToken: String){
                    getAccountancyProjectConfigs(limit: $limit, nextToken: $nextToken){
                        items{
                            projectId
                            projectName
                        }
                        nextToken
                    }

                }
          `
        ,
        variables: {
            limit,nextToken
        }
  
      });
  
      console.log(response?.data?.data?.getAccountancyProjectConfigs);
      return response?.data?.data?.getAccountancyProjectConfigs;
    } catch (error) {
      console.error("error reuerations des projets factures :", error);
      throw error;
    }
};

export const getAccountancyIssuerConfig = async (projectId) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
             query getAccountancyIssuerConfig($projectId: String!){
          getAccountancyIssuerConfig(projectId: $projectId){
              projectId
              companyName
              socialStatus
              siret
              contactName
              address
              phoneNumber
              email
              vatNumber
              nafNumber
              capitalStockAmount
              capitalStockCurrency
              iban
              bicSwift
          }
      }
        `
      ,
      variables: {
        projectId
      }

    });

    console.log(response?.data?.data?.getAccountancyIssuerConfig);
    return response?.data?.data?.getAccountancyIssuerConfig;
  } catch (error) {
    console.error("error reuerations des  info projets factures :", error);
    throw error;
  }
};
export const putAccountancyIssuerConfig = async (input) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
             mutation putAccountancyIssuerConfig($input: AccountancyIssuerInput) {
            putAccountancyIssuerConfig(input: $input)
        }
        `
      ,
      variables: {
        input
      }

    });

    console.log(response?.data?.data?.putAccountancyIssuerConfig);
    return response?.data?.data?.putAccountancyIssuerConfig;
  } catch (error) {
    console.error("error upldate  des  info projets factures :", error);
    throw error;
  }
};

 /*upoad files*/
 export const getLogoUpload = async (projectId,fileName) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
        mutation generateS3AccountancyLogoPreSignedUploadUrl($projectId: String!, $fileName: String!) {
            generateS3AccountancyLogoPreSignedUploadUrl(projectId: $projectId, fileName: $fileName) {
                    url
                    fields {
                        key
                        xAmzAlgorithm
                        xAmzCredential
                        xAmzDate
                        xAmzSecurityToken
                        policy
                        xAmzSignature
                    }
            }
        }
      `,
      variables: {
        projectId,fileName,
      },
    });
    return response.data.data.generateS3AccountancyLogoPreSignedUploadUrl;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};
export const uploadLogoToS3 = async (projectId,file) => {
  try {
    const data = await getLogoUpload(projectId,file.name);
    console.log("data", data.fields.key);
   const  s3Key=data.fields.key

    const response = await axiosInstance.post('/graphql', {
      query: `
              mutation updateAccountancyLogo($projectId: String!, $s3Key: String!) {
              updateAccountancyLogo(projectId: $projectId, s3Key: $s3Key)
          }
      `,
      variables: {
        projectId,s3Key,
      },
    });
    return response.data.data.updateAccountancyLogo;
  

  
  } catch (error) {
    console.error("Erreur lors du chargement du logo :", error);
    throw error;
  }
};

//recuperere logo
export const VisualiserLogo = async (projectId) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: 
        `
          query getAccountancyLogo($projectId: String!){
            getAccountancyLogo(projectId: $projectId){
                s3Key
            } 
        }      
        `,
        variables: {
          projectId,
        },
    });
    return response.data.data.getAccountancyLogo.s3Key;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};

export const telechargerLogo = async (s3Key) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: 
        `
          mutation generateAccountancyS3PreSignedDownloadUrl($s3Key: String!,) {
              generateAccountancyS3PreSignedDownloadUrl(s3Key: $s3Key) {
                      url
              }
          }      
        `,
        variables: {
          s3Key,
        },
    });
    return response.data.data.generateAccountancyS3PreSignedDownloadUrl.url;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'URL pré-signée:', error);
    throw error;
  }
};
