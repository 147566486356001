
import { Accordion, AccordionDetails, AccordionSummary,  TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


 const  FicheDirigeant=({partnerData})=>{

    const [partners, setPartners]=useState([]);
      useEffect(() => {
              if (partnerData) {
                setPartners(partnerData);
              }
            }, [partnerData]);
    
      const handlePartnerChange = (e, partnerId) => {
        const { id, value } = e.target;
      
        setPartners((prevPartners) =>
          prevPartners.map((partner) => {
            if (partner.partnerId === partnerId) {
              if (id.startsWith('spouse')) {
                const spouseField = id.replace('spouse', ''); 
                const fieldKey = spouseField.charAt(0).toLowerCase() + spouseField.slice(1);
                return {
                  ...partner,
                  spouseIdentity: {
                    ...partner.spouseIdentity,
                    [fieldKey]: value,
                  },
                };
              }
              return { ...partner, [id]: value };
            }
            return partner;
          })
        );
      };
      const handleNewPartnerChange = (e) => {
        const { id, value } = e.target;
      
        setPartners((prev) => {
          const newPartner = prev.find((p) => p.partnerId === 'newPartner') || {
            partnerId: 'newPartner',
          };
      
        return [
            ...prev.filter((p) => p.partnerId !== 'newPartner'), 
            { ...newPartner, [id]: value },
          ];
        });
      };    

    return(
        <div className="row">
            {partners.some((partner) => partner.isMainLeader) ? (
              partners
                .filter((partner) => partner.isMainLeader)
                .map((leader) => (
                  <React.Fragment key={leader.partnerId}>
                    <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                      <AccordionSummary >
                        <h4>
                          <ExpandMoreIcon style={{ marginRight: '8px' }} /> Fiche dirigeant
                        </h4>
                    </AccordionSummary>

                        <AccordionDetails>
                          {[
                            { label: 'Prénom', id: 'firstNames', placeholder: 'Prénom' },
                            { label: 'Nom', id: 'name', placeholder: 'Nom' },
                            { label: 'Lieu de naissance', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                            { label: 'Date de naissance', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                            { label: 'Situation professionnelle', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                            { label: 'Numéro de sécurité sociale', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                            { label: 'Nationalité', id: 'nationality', placeholder: 'Nationalité' },
                          ].map((field) => (
                            <div key={field.id} className="mb-4">
                              <TextField
                                label={field.label}
                                fullWidth
                                variant="outlined"
                                value={leader[field.id] || ''} 
                                placeholder={field.placeholder}
                                onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                                id={field.id}
                                type={field.type || 'text'}
                                InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                              />
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                      <AccordionSummary>
                        <h4><ExpandMoreIcon style={{ marginRight: '8px' }} /> Conjoint(e)</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        {[
                          { label: 'Prénom', value: leader.spouseIdentity?.firstNames || '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                          { label: 'Nom', value: leader.spouseIdentity?.name || '', id: 'spouseName', placeholder: 'Nom' },
                          { label: 'Nom de jeune fille', value: leader.spouseIdentity?.maidenName || '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                          { label: 'Lieu de naissance', value: leader.spouseIdentity?.placeOfBirthday || '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                          { label: 'Date de naissance', value: leader.spouseIdentity?.dateOfBirthday || '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                          { label: 'Nationalité', value: leader.spouseIdentity?.nationality || '', id: 'spouseNationality', placeholder: 'Nationalité' },
                        ].map((field) => (
                          <div key={field.id} className="mb-4">
                            <TextField
                              label={field.label}
                              fullWidth
                              variant="outlined"
                              value={field.value} 
                              placeholder={field.placeholder}
                              onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                              id={field.id}
                              type={field.type || 'text'}
                              InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                            />
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary >
                          <h4>
                            <ExpandMoreIcon style={{ marginRight: '8px' }} /> Fiche dirigeant
                          </h4>
                      </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'firstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'name', placeholder: 'Nom' },
                        { label: 'Lieu de naissance', value: '', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Situation professionnelle', value: '', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                        { label: 'Numéro de sécurité sociale', value: '', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                        { label: 'Nationalité', value: '', id: 'nationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                      <h4><ExpandMoreIcon style={{ marginRight: '8px' }} /> Conjoint(e)</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'spouseName', placeholder: 'Nom' },
                        { label: 'Nom de jeune fille', value: '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                        { label: 'Lieu de naissance', value: '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Nationalité', value: '', id: 'spouseNationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </React.Fragment>
            )}
          </div>
    )

 };
 export default FicheDirigeant;  