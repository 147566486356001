import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom'; 
import DashboardHeader from '../header';
import ComposeEmailModal from './ComposeEmailModal';
import { ClipLoader } from "react-spinners";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  TablePagination,
  
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getNumberOfMails, getReceivedMails,getDraftMails, getSentMails, getAllContact, deleteEmail, deleteContact,  fetchDownloadAttachment } from '../../../../api/Services/emailService';
import Swal from 'sweetalert2';
import AddContactModal from './addContact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt,faPaperclip } from '@fortawesome/free-solid-svg-icons';
import PaginationComponent from '../../../pagination/pagination';
import { FaEllipsisV } from 'react-icons/fa';
import DeplacerDocumentVersDMS from './attachementVersDMS';


const DashboardMessage = ({ setIsOpenSidebar }) => {
  const { emailAddress } = useParams();
  const navigate= useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("received"); 
  const [emails, setEmails] = useState([]);
  const [receivedEmails, setReceivedEmails] = useState([]);
  const [sentEmails, setSentEmails] = useState([]);
  const [draftEmails, setDraftEmails] = useState([]);
  const [contactEmails, setContactEmails] = useState([]);
  const [allEmail, setAllEmail] = useState(0);
  const [unReadEmail, setUnReadEmail] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isComposeModalOpen, setComposeModalOpen] = useState(false);
  const [attachments,setAttachments]=useState([]);
  const handleCloseDeplacer = () => setShowModalDeplcer(false);
   const handleShowDeplacer = () => {  setShowModalDeplcer(true)};
   const [showDeplacer,setShowModalDeplcer]=useState(false);
  const [page, setPage] = useState(0);  
  const [rowsPerPage, setRowsPerPage] = useState(5);  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  }


  const openComposeModal = () => {
    setComposeModalOpen(true);
  };

  const closeComposeModal = () => {
    setComposeModalOpen(false);
  };
  const fetchEmails = async () => {
    setIsLoading(true);
    try {
      const numberResponse = await getNumberOfMails(emailAddress);
      setAllEmail(numberResponse?.totalEmailsCount + numberResponse?.unreadEmailsCount || 0);
      setUnReadEmail(numberResponse?.unreadEmailsCount || 0);
      
      const [received, sent, draft,contact] = await Promise.all([
        getReceivedMails(emailAddress),
        getSentMails(emailAddress),
        getDraftMails(emailAddress),
        getAllContact(emailAddress),
      ]);
       
      setReceivedEmails(received.items || []);
      setSentEmails(sent.items || []);
      setDraftEmails(draft.items || []);
      setContactEmails(contact.items || []);
    } catch (error) {
      console.error("Error fetching emails:", error);
     
    }finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
   if (emailAddress) {
      fetchEmails();
    }
  }, [emailAddress]);

useEffect(() => {
  if (filter === "received") {
    setEmails(receivedEmails.map(email => ({ ...email, isSelected: false })));
  } else if (filter === "sent") {
    setEmails(sentEmails.map(email => ({ ...email, isSelected: false })));
  } else if (filter === "draft") {
    setEmails(draftEmails.map(email => ({ ...email, isSelected: false })));
  } else if (filter === "contact") {
    setEmails(contactEmails.map(contact => ({ ...contact, isSelected: false })));
  }
}, [filter, receivedEmails, sentEmails, draftEmails, contactEmails]);

  
  const handleRowClick = (email) => {
    setSelectedEmail(email);
    setAttachments(email.attachments || []);
    console.log("Attachments:", attachments);

  };

  const handleSelectEmail = (email) => {
    setEmails((prevEmails) =>
      prevEmails.map((e) =>
        e.itemId === email.itemId ? { ...e, isSelected: !e.isSelected } : e
      )
    );
  };

 
  const extractName = (sender) => {
    const match = sender.match(/name='([^']+)'/);
    return match ? match[1] : 'Unknown Name';
 

  };
  const handleSelectAll = (e) => {
    const isSelected = e.target.checked;
    setEmails((prevEmails) =>
      prevEmails.map((email) => ({ ...email, isSelected }))
    );
  };
  const handleDeleteSelected = async () => {
    const selectedEmails = emails.filter((email) => email.isSelected);
    
    if (selectedEmails.length > 0) {
      const emailIds = selectedEmails.map((email) => email.itemId);
    
      const result = await Swal.fire({
        icon: "warning",
        title: "Suppression des emails",
        text: `Vous voulez vraiment supprimer ${selectedEmails.length} email(s) ?`,
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
      });
    
      if (result.isConfirmed) {
        try {
          Swal.fire({
            title: "Suppression en cours...",
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
    
          const response = await deleteEmail(emailAddress, emailIds);
          if (response?.data?.deleteMails === null) {
            // Actualiser la liste des emails
            await fetchEmails();
            Swal.fire({
              icon: "success",
              title: "Supprimé",
              text: `${selectedEmails.length} email(s) ont été supprimé(s) avec succès.`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Erreur",
              text: "Les emails n'ont pas été supprimés.",
            });
          }
        } catch {
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Une erreur est survenue.",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Aucune sélection",
        text: "Veuillez sélectionner au moins un email.",
      });
    }
  };
  
  

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {setIsModalOpen(true);}

  const handleCloseModal = () =>{ setIsModalOpen(false);
    
  }
 


  const handleDelete = async (itemId) => {
    try {
      const response = await deleteContact(emailAddress, [itemId]);
      if (response?.data) {
        Swal.fire({
          icon: "success",
          title: "Succès",
          text: "Contact supprimé avec succès.",
        });
        const updatedContacts = await getAllContact(emailAddress);
        setContactEmails(updatedContacts.items || []);
      } else {
        throw new Error("Erreur lors de la suppression du contact.");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du contact :", error);
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Impossible de supprimer le contact. Veuillez réessayer.",
      });
    } finally {
      await fetchEmails(emailAddress); 
    }
  };
  
  
  
  /* partie search*/
  const [searchTerm, setSearchTerm] = useState("");

  const filteredItems = emails.filter((email) =>
    email.subject?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.sender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.recipient?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const filteredContacts = contactEmails.filter((contact) =>
    contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  /* partie dawlond fils*/
 
  const dowloadSingleAttachment = async (attachment) => {
    try {
      if (!emailAddress) {
        console.error("L'adresse e-mail source est manquante.");
        return;
      }
  
      const url = await fetchDownloadAttachment(
        emailAddress,
        selectedEmail.itemId,
        attachment.attachmentId
      );
  
      if (url) {
        window.open(url, '_blank');
      } else {
        console.warn("URL de téléchargement non disponible pour :", attachment.name);
      }
    } catch (error) {
      console.error(`Erreur lors du téléchargement de la pièce jointe : ${attachment.name}`, error);
    }
  };
  
  

  return (
    <div className="dashboard-body">
      
      <div className="position-relative ">
          
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <div className="bg-white card-box border-20 p0 mt-30 h-100">
          <div className="message-wrapper">
            <div className="row gx-0 h-100">
              <div className="col-lg-3 ">
                <div className="message-sidebar ps-3 pe-3 ps-xxl-4 pe-xxl-4 mt-4">
              
                  <div className="email_interface">
                    <button className="compose_button" onClick={openComposeModal}>
                      <i className="fas fa-pencil-alt"></i> Rédiger un email
                    </button>
                        {isComposeModalOpen && (
                    <ComposeEmailModal onClose={closeComposeModal}addressEmail={emailAddress} />
                  )}       
                    <div className="message_container">
                      <div className="message_filter" id="module_btns">
                        <div 
                          className={`filter_item ${filter === "received" ? 'active' : ''}`} 
                          onClick={() => setFilter("received")}
                        >
                          <i className="fas fa-envelope"></i>
                          <span className="filter_text">Boite de réception</span>
                          <span className="filter_count"> {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              receivedEmails?.length || 0
                            )}</span>
                        </div>
                        <div 
                          className={`filter_item ${filter === "sent" ? 'active' : ''}`} 
                          onClick={() => setFilter("sent")}
                        >
                          <i className="fas fa-paper-plane"></i>
                          <span className="filter_text">Messages envoyés</span>
                          <span className="filter_count"> {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              sentEmails?.length || 0
                            )}</span>
                        </div>
                        <div 
                          className={`filter_item ${filter === "draft" ? 'active' : ''}`} 
                          onClick={() => setFilter("draft")}
                        >
                          <i className="fas fa-pencil-alt"></i>
                          <span className="filter_text">Brouillons</span>
                          <span className="filter_count">
                            {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              draftEmails?.length || 0
                            )}
                          </span>
                        </div>
                        <div 
                          className={`filter_item ${filter === "contact" ? 'active' : ''}`} 
                          onClick={() => setFilter("contact")}
                        >
                          <i className="fa-solid fa-user-alt"></i>
                          <span className="filter_text">Contacts</span>
                          <span className="filter_count"> {isLoading ? (
                              <ClipLoader size={10} color="white" />
                            ) : (
                              contactEmails?.length || 0
                            )}</span>
                        </div>
                        <div 
                          className="filter_item"
                          onClick={() => navigate(`/email-calendrier/${emailAddress}`)}
                        >
                          <i className="fa-solid fa-calendar-days"></i>
                          <span className="filter_text">Calendrier</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div className="message-sidebar ">
                  <div className="ps-3 pe-3 ps-xxl-4 pe-xxl-4">
                    
                      <div className="search-form mt-20 mb-20">
                        <input
                          type="text"
                          placeholder="Rechercher..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="form-control"
                        />
                        <button>
                          <img
                            src="/assets/dashboard/images/icon/icon_10.svg"
                            alt="icon"
                            className="lazy-img m-auto"
                          />
                        </button>
                      </div>

                      {emails.some(email => email.isSelected) && (
                         <button className="delete-selected-btn" onClick={handleDeleteSelected}>
                         <FontAwesomeIcon icon={faTrashAlt} className="icon-trash" />
                       </button>
                        )}
                    </div>
                    {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                      <ClipLoader size={50} color="#19AF78" />
                    </div>
                     ) :
                    filter === "contact" ? (
                      <>
                      <div
                        className="d-flex flex-column align-items-end ps-3 pe-3 ps-xxl-4 pe-xxl-4 mt-3 mb-4"
                        style={{ width: "100%" }}
                      >
                        <Link
                          className="dash-btn-two tran3s mb-3"
                          style={{
                            display: "inline-block",
                            borderRadius: "5px",
                            textDecoration: "none",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={handleOpenModal}
                        >
                          Ajouter un Contact
                        </Link>

                        {isModalOpen && (
                          <AddContactModal
                            open={isModalOpen}
                            handleClose={handleCloseModal}
                            adressEmail={emailAddress}
                            fetchEmails={fetchEmails}
                          />
                        )}

                        <TableContainer component={Paper} style={{ width: "100%" }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Prénom</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Téléphone</TableCell>
                                <TableCell align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {filteredContacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((contact) => (

                              <TableRow key={contact.itemId}>
                                <TableCell>{contact.name || "—"}</TableCell>
                                <TableCell>{contact.surname || "—"}</TableCell>
                                <TableCell>{contact.emails || "—"}</TableCell>
                                <TableCell>{contact.phoneNumbers || "—"}</TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    color="error"
                                    onClick={() => handleDelete(contact.itemId)}
                                    aria-label="Supprimer le contact"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}

                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredContacts.length}  
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                      </div>
                      </>

                      ) : selectedEmail ? (
                        <div className="email-details-container">
                        <button
                          className="back-to-table-btn"
                          onClick={() => setSelectedEmail(null)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "20px",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <i className="fas fa-arrow-left" style={{ marginRight: "15px" }}></i>
                          Retour à la liste
                        </button>
                      
                        <div className="open-email-container pb-40">
                          <div className="email-header divider d-flex justify-content-between ps-4 pe-4 ps-xxl-5 pe-xxl-5">
                            <div className="sender-info d-flex align-items-center">
                              <h3>{selectedEmail.subject}</h3>
                            </div>
                            <div className="sender-info d-flex align-items-center">
                              
                              <div className="sender-name">
                               
                              </div>
                            </div>
                          </div>
                      
                          <div className="email-body divider">
                            <div className="ps-4 pe-4 ps-xxl-5 pe-xxl-5">
                                 {filter=== "received" ? (
                                  <>De {extractName(selectedEmail.sender)}, le {selectedEmail.time}</>
                                ) : (
                                  <>À {extractName(selectedEmail.sender)}, le {selectedEmail.time}</>
                                )}
                              </div>
                          </div>
                          <div className="email-body divider">
                            <div className="ps-4 pe-4 ps-xxl-5 pe-xxl-5">
                            <p dangerouslySetInnerHTML={{ __html: selectedEmail.body }}></p>
                              {/* <p >{selectedEmail.body }</p> */}
                            </div>
                          </div>

                          <div className="email-footer">
                            <div className="ps-4 pe-4 ps-xxl-5 pe-xxl-5">
                              <div className="col-4 attachments mb-30">
                                <h6 className="m0">Attachments</h6>
                                {attachments?.length > 0 ? (
                                  attachments.map((attachment, index) => (
                                    <div key={index} className="file tran3s d-flex align-items-center mt-10 position-relative">
                                      
                                        <div className="icon rounded-circle d-flex align-items-center justify-content-center">
                                          <img
                                            src="/assets/dashboard/images/icon/icon_31.svg"
                                            alt="icon"
                                            className="lazy-img"
                                          />
                                        </div>
                                        <div className="ps-2">
                                          <div className="file-name">{attachment.name}</div>
                                        </div>
                                     

                                                  <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
                                                             <button
                                                               className="action-btn dropdown-toggle position-absolute"
                                                               type="button"
                                                               data-bs-toggle="dropdown"
                                                               aria-expanded="false"
                                                               style={{ top: '0px', right: '0px' }}
                                                             >
                                                               <FaEllipsisV/>
                                                             </button>
                                                             <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                                                             <li>
                                                                 <Link className="dropdown-item "  onClick={() => dowloadSingleAttachment(attachment)}
                                        
                                                                   >
                                                                   <img
                                                                    src="https://img.icons8.com/ios-glyphs/24/000000/download.png"
                                                                     alt="Restaurer icon"
                                                                     className="lazy-img"
                                                                     style={{ width: '20px', height: '20px' }}
                                                                   />
                                                                   Télecharger
                                                                 </Link>
                                                               </li>
                                                               <li>
                                                                 <Link className="dropdown-item"  onClick={() => handleShowDeplacer(attachment.attachmentId)}>
                                                                   <img
                                                                     src="https://img.icons8.com/ios-filled/24/000000/undo.png"
                                                                     alt="Restaurer icon"
                                                                     className="lazy-img"
                                                                     style={{ width: '20px', height: '20px' }}
                                                                   />
                                                                   Placer vers DMS
                                                                 </Link>
                                                               </li>
                                                              
                                                             </ul>
                                                           </div>
                                                           {showDeplacer && (
                                                                  <DeplacerDocumentVersDMS
                                                                    adressEmail={emailAddress}
                                                                    showDeplacer={showDeplacer}
                                                                    itemId={selectedEmail.itemId}
                                                                    attachmentId={attachment.attachmentId}
                                                                    handleCloseDeplacer={handleCloseDeplacer}
                                                                />
                                                                )}
                                     
                                    
                                    </div>
                                  ))
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            </div>
                          </div>

                          </div>
                        </div>
                        
                      
                      ) : (
                        <div className="emails-content p-3">
                          {emails.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>
                                      <input type="checkbox" onChange={handleSelectAll} />
                                    </th>
                                    <th>Expéditeur</th>
                                    <th>Objet</th>
                                    <th>Pièce jointe</th>
                                    <th>Heure</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredItems
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((email, index) => (
                                      <tr key={index} onClick={() => handleRowClick(email)}>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={email.isSelected}
                                            onClick={(e) => e.stopPropagation()} 
                                            onChange={() => handleSelectEmail(email)}
                                          />
                                        </td>
                                        <td>{extractName(email.sender)}</td>
                                        <td>{email.subject}</td>
                                        <td >
                                          {email.attachments && email.attachments.length > 0 ? (
                                            <FontAwesomeIcon
                                              icon={faPaperclip}
                                              className="d-flex justify-content-center"
                                            />
                                          ) : null}
                                        </td>

                                        <td>{new Date(email.time).toLocaleString()}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p>Aucun email trouvé.</p>
                          )}
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredContacts.length}  
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>

                      )}

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default DashboardMessage;
