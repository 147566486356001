import React, { useState } from "react";
import { UserPurchaseS3 } from "../../../../api/Services/achatService";

const CandidateItem = ({ item }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);

  const handlePdfClick = async () => {
    try {
      const response = await UserPurchaseS3(item.id, item.location);
      if (response.url) {
        setPdfUrl(response.url);
        setShowPdf(true);
      } else {
        console.error("URL du PDF non valide");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du PDF:", error);
    }
  };

  const handleDownloadClick = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "document.pdf";
      link.click();
    }
  };

  return (
    <div className="candidate-profile-card list-layout border-0 mb-20">
      <div className="d-flex">
        <div className="right-side w-100">
          <div className="row gx-1 align-items-center">
            <div className="col-xl-4">
              <div className="position-relative">
                <h4 className="candidate-name mb-0">
                  <a href="#" className="tran3s">
                    {item.name}
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6">
              <div className="candidate-info">
                <div>{item.date}</div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6">
              <div className="candidate-info">
                <div>{item.price}</div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2">
              <div className="d-flex justify-content-md-end align-items-center">
                <button onClick={handlePdfClick} className="d-block">
                  <img
                    src="/assets/images/icon/pdf.svg"
                    alt="PDF icon"
                    width="40"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPdf && pdfUrl && (
        <div className="pdf-viewer">
          <div className="viewer-header">
            <button onClick={() => setShowPdf(false)} className="close-button">
              x
            </button>
            <button onClick={handleDownloadClick} className="download-button">
              Télécharger
            </button>
          </div>
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}

      <style jsx>{`
        .candidate-profile-card.list-layout {
          padding: 20px 10px 25px;
        }
        .pdf-viewer {
          position: fixed;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          background: #f8f8f8;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }
        .viewer-header {
          display: flex;
          justify-content: flex-end;
          gap: 10px;
          padding: 10px;
        }
        .close-button {
          background: red;
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
        .download-button {
          background: rgb(25, 175, 120);
          color: #fff;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px;
        }
      `}</style>
    </div>
  );
};

export default CandidateItem;
