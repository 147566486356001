import { Accordion, AccordionDetails, AccordionSummary, Drawer, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FicheDocument=() =>{
    const [showModal, setShowModal] = useState(false);

     const [isOpenGeneral, setIsOpenGeneral] = useState(false);
    const [isOpenEntreprise, setIsOpenEntreprise] = useState(false);
    const [isOpenDocument, setIsOpenDocument] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const toggleAccordionGeneral = () => setIsOpenGeneral(!isOpenGeneral);
    const toggleAccordionEntreprise = () => setIsOpenEntreprise(!isOpenEntreprise);
    const toggleAccordionDocument = () => setIsOpenDocument(!isOpenDocument);
    const [showModalAddAssocie, setShowModalAddAssocie] = useState(false);
    
    const handleOpenAddAssocie = () => setShowModalAddAssocie(true);
    const handleCloseAddAssocie = () => setShowModalAddAssocie(false);
    const handleAddAssocie = () => {
    
        handleCloseAddAssocie();
    };
    const handleIconClick1 = (iconClass) => {
        if (iconClass.includes("text-success")) {
        setDrawerOpen(true);
        }
    };
    const handleIconClick = (status) => {

    }; 
    const handleUpload = () => {
        if (selectedFile) {
    
        }
        setShowModal(false);
      };
      const [selectedFile, setSelectedFile] = useState(null);
      const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };
    return (
           <div className="col-md-12">
                <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary >
                            <h4>
                              <ExpandMoreIcon style={{ marginRight: '8px' }} /> Document à fournir
                            </h4>
                        </AccordionSummary>
            
                        <div className="table-responsive mt-20 mb-20" id="liste-table">
                          <table className="table job-alert-table">
                            <tbody>
                              <tr>
                                <td className="text-left p-2">nom pièce 1</td>
                                <td className="text-center p-2">
                                  <i
                                    className="fas fa-file-alt text-success fs-4"
                                    onClick={() => handleIconClick1("text-success")}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </td>
                                <td className="text-center p-2">En attente/validé/rejeté</td>
                                <td className="text-center p-2">message du cabinet comptable</td>
                                <td className="text-center p-2">
                                  <button onClick={() => setShowModal(true)}>Charger</button>
                                </td>
                              </tr>
                              <tr>
                                <td className="text-left p-2">nom pièce 2</td>
                                <td className="text-center p-2">
                                  <i
                                    className="fas fa-file-alt text-danger fs-4"
                                    onClick={() => handleIconClick("text-danger")}
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </td>
                                <td className="text-center p-2">En attente/validé/rejeté</td>
                                <td className="text-center p-2">message du cabinet comptable</td>
                                <td className="text-center p-2">
                                  <button onClick={() => setShowModal(true)}>Charger</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                              <Modal.Title>Upload un nouveau fichier</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <input type="file" onChange={handleFileChange} />
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Annuler
                              </Button>
                              <Button variant="primary" onClick={handleUpload}>
                                Charger
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          {/* Drawer */}




                        </div>
                </Accordion>
            </div>
    )
};
export default FicheDocument;