import React ,{useState,useEffect} from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { getWebpackageOrders } from '../../../../api/Services/webPackageService';


const Communication = ({setIsOpenSidebar}) => {

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getWebpackageOrders();
        setProjects(response?.data?.getWebpackageOrders?.items || []);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
    console.log(fetchProjects())

  }, []);
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title m0 h2-titel">Pharos WebPro</h2>          
        </div>
        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
         
             <div className="col-12">
                  {projects.map((project) => (
                         <button  key={project.projectId} type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                        <Link to={`/info-projet/${project.projectId}`}>{project.projectName}</Link>
                            

                        </button>
                        ))}
                      
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="/paiement-package-web">Commander un nouveau projet</Link>
                        </button>
                        </div>
                         
          </div>
        </div>
      
          
      </div>      
    </div>
  );
};

export default Communication;