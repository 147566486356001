"use client";
import React from "react";
import DashboardHeader from "./header";
import Calendar from './rdv/calendar'; 
import { Link } from "react-router-dom";


const EmployDashboardArea = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h2 className="main-title mb-10 h2-titel">Mon Compte HaSen</h2>       
        
        <div className="bg-white card-box border-20 col-12">                          
          <div className="row justify-content-center">
              <div className="button-group d-inline-flex col-12 align-items-center justify-content-center">
                  <Link to="/mes-demarches" className="dash-btn-two tran3s me-3 card_dash">Pharos Legal - Mes démaraches</Link>          
              </div>  
              <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
                <Link to="/ma-communication" className="dash-btn-two tran3s me-3 card_dash">Pharos WebPro</Link>          
              </div>   
              <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
                <Link to="/ma-facturation-index" className="dash-btn-two tran3s me-3 card_dash">Pharos Compta - Ma facturation</Link>          
              </div>                 
              <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
                <Link to="/ged" className="dash-btn-two tran3s me-3 card_dash">GED (Gestionnaires éléctroniques de documents)</Link>          
              </div> 
              <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
                <Link to="/boites-mail" className="dash-btn-two tran3s me-3 card_dash">Boites mail</Link>          
              </div>
              <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
                <Link to="/mes-achats" className="dash-btn-two tran3s me-3 card_dash">Mes achats</Link>          
              </div>
              <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
                <Link to="/profile" className="dash-btn-two tran3s me-3 card_dash">Mes informations personnelles</Link>          
              </div>
          </div> 
        </div>                                                                                                                                                                                                                                 
      </div>
    </div>
  );
};

export default EmployDashboardArea;
