import React,{useState} from 'react';
import EmployAside from '../../aside';
import DemarchesCreate from './mes-demarches-create';

const MesDemarchesCreate = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <DemarchesCreate setIsOpenSidebar={setIsOpenSidebar}/>
    </div>    
  );
};

export default MesDemarchesCreate;