
const initialState = {
  token: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'CLEAR_TOKEN':
      return { ...state, token: null };
    default:
      return state;
  }
}

export const setToken = (token) => ({ type: 'SET_TOKEN', payload: token });
export const clearToken = () => ({ type: 'CLEAR_TOKEN' });

export default authReducer;
