const initialState = {
    statut: "",
  };
  
  function statutReducer(state = initialState, action) {
    switch (action.type) {
      case 'SET_STATUT':
        console.log("statutredux--");
        return { ...state, statut: action.payload };
      default:
        return state;
    }
  }
  
  // Actions
  export const setStatut = (statut) => ({ type: 'SET_STATUT', payload: statut });
  
  export default statutReducer;
  