import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProject } from '../../../redux/packageSlice';

const CandidateV3Area = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});

    const [selectedSocialNetwork, setSelectedSocialNetwork] = useState(projectData.socialNetworks || {});

    useEffect(() => {
        setSelectedSocialNetwork(projectData.socialNetworks || {});
    }, [projectData]);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setSelectedSocialNetwork(prevState => ({
            ...prevState,
            [id]: value,  
        }));
    };

    const handleSubmit = () => {
        dispatch(setProject({
            projectId,
            data: {
                website: {
                    globalDetails: {
                        socialNetworks: selectedSocialNetwork
                    }
                }
            }
       }));
       
        console.log("Updated Social Networks on submit:", selectedSocialNetwork);
    };

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: '135px', paddingBottom: '30px' }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="d-flex align-items-center justify-content-center">
                            <Link to={`/package-com6/${projectId}`} className="btn btn-success">
                                {"< Précédent"}
                            </Link>
                            <span className="mx-3">9/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">Avez-vous des réseaux sociaux ?</h4>
                    </div>

                    {['instagram', 'facebook', 'snapchat', 'twitter', 'youtube', 'linkedin'].map((platform) => (
                        <div className="col-12 mb-4" key={platform}>
                            <div className="input-group-meta position-relative mb-25">
                                <label htmlFor={platform}>{platform.charAt(0).toUpperCase() + platform.slice(1)}</label>
                                <input
                                    type="text"
                                    id={platform}
                                    value={selectedSocialNetwork[platform] || ''} 
                                    placeholder={`lien ${platform.charAt(0).toUpperCase() + platform.slice(1)}`}
                                    className="form-control"
                                    onChange={handleInputChange} 
                                />
                            </div>
                        </div>
                    ))}

                    <div className="row mt-5">
                        <div className="col-12">
                            <button type="button" onClick={handleSubmit} className="btn-eleven fw-500 tran3s d-block mt-20">
                              <Link to={`/package-com8/${projectId}`} className="d-block">
                                    Validez
                                </Link>                            </button>
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com8/${projectId}`} className="d-block">
                                    Passez cette étape
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
