import React,{useState} from 'react';
import EmployAside from '../../aside';
import FacturesIndex from './facture';

const Factures = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className='main-page-wrapper'>
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>

      <FacturesIndex setIsOpenSidebar={setIsOpenSidebar}/>
    </div>
  );
};

export default Factures;