"use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";



const CandidateV3Area = ({ style_2 }) => {
    const [jobType, setJobType] = useState(style_2 ? "list" : "grid");


    return (
        <>
                    <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="d-flex align-items-center justify-content-center">
                            <Link to="/package-com3">
                                <button type="button" className="btn btn-success">
                                    {"< Précédent"}
                                </button>
                            </Link>
                            <span className="mx-3">7/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">
                            Commandez des objets personnalisés avec votre logo
                        </h4>
                    </div>
                    <div className="row mt-5">
                        {/* Cards for items */}
                        <div className="col-md-4 mb-4">
                            <div className="card border rounded shadow-sm p-3">
                                <div className="card-body text-center">
                                    <h5 className="card-title">T-shirt</h5>
                                    <p className="card-text">Prix: $XX</p>
                                    <label htmlFor="quantityTshirt" className="d-block mb-2">Choisir quantité</label>
                                    <input type="number" id="quantityTshirt" className="form-control mb-2" min="1" defaultValue="1" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card border rounded shadow-sm p-3">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Mug</h5>
                                    <p className="card-text">Prix: $XX</p>
                                    <label htmlFor="quantityMug" className="d-block mb-2">Choisir quantité</label>
                                    <input type="number" id="quantityMug" className="form-control mb-2" min="1" defaultValue="1" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card border rounded shadow-sm p-3">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Carte de visite</h5>
                                    <p className="card-text">Prix: $XX</p>
                                    <label htmlFor="quantityBusinessCard" className="d-block mb-2">Choisir quantité</label>
                                    <input type="number" id="quantityBusinessCard" className="form-control mb-2" min="1" defaultValue="1" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card border rounded shadow-sm p-3">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Cahier</h5>
                                    <p className="card-text">Prix: $XX</p>
                                    <label htmlFor="quantityNotebook" className="d-block mb-2">Choisir quantité</label>
                                    <input type="number" id="quantityNotebook" className="form-control mb-2" min="1" defaultValue="1" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card border rounded shadow-sm p-3">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Flyers</h5>
                                    <p className="card-text">Prix: $XX</p>
                                    <label htmlFor="quantityFlyers" className="d-block mb-2">Choisir quantité</label>
                                    <input type="number" id="quantityFlyers" className="form-control mb-2" min="1" defaultValue="1" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card border rounded shadow-sm p-3">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Autre</h5>
                                    <p className="card-text">Prix: $XX</p>
                                    <label htmlFor="quantityOther" className="d-block mb-2">Choisir quantité</label>
                                    <input type="number" id="quantityOther" className="form-control mb-2" min="1" defaultValue="1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to="/package-com6" className="d-block">
                                    Validez
                                </Link>
                            </button>
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to="/package-com6" className="d-block">
                                    Passez cette étape
                                </Link>
                            </button>
                            {/* <button 
                                        type="submit" 
                                        className="btn-eleven fw-500 tran3s d-block mt-20"
                                        style={{ backgroundColor: 'red', color: 'white' }}  
                                    >
                                        <Link 
                                            to="/package-com6" 
                                            className="d-block"
                                        >
                                            A ne pas faire pour le moment
                                        </Link>
                                    </button> */}

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default CandidateV3Area;
