import React from "react";

const AccordionItem = ({ id, title, isShow, desc, toggleItem }) => {
  return (
    <div className="accordion-item">
      <div className="accordion-header" id={`heading-${id}`}>
        <button
          className={`accordion-button ccordion_item ${isShow ? "" : "collapsed"}`}
          type="button"
          onClick={toggleItem} // Gestionnaire d'événements pour basculer l'état
          aria-expanded={isShow ? "true" : "false"}
          aria-controls={`collapse-${id}`}
        >
          {title}
        </button>
      </div>
      <div
        id={`collapse-${id}`}
        className={`accordion-collapse collapse ${isShow ? "show" : ""}`}
        aria-labelledby={`heading-${id}`}
      >
        <div className="accordion-body">
          <p>{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
