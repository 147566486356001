import React, { useEffect, useState } from 'react';
import JobAlertItem from './mes-demarche-item';
import DashboardHeader from '../../header';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Stack,
} from '@mui/material';
import { styled } from '@mui/system';
import { fetchGetCompany } from '../../../../../api/Services/companyService';
import { useDispatch } from 'react-redux';
import { setProjet } from '../../../../../redux/projetCommunication';
import { getStatusTranslation } from '../../../../../Constantes/Constante';
import PaginationComponent from '../../../../pagination/pagination';

const StyledTableContainer = styled(TableContainer)({
  backgroundColor: '#fff',
  borderRadius: '20px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
});

const StyledTableCell = styled(TableCell)({
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#333',
  verticalAlign: 'middle',
});

const StyledButton = styled(Button)({
  backgroundColor: 'rgb(25, 175, 120)',
  color: '#fff',
  borderRadius: '20px',
  padding: '8px 16px',
  textTransform: 'none',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: 'rgb(25, 175, 120)',
    color: '#fff',
  },
});

const MesDemarches = ({ setIsOpenSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const data = await fetchGetCompany();
        const projectItems = data?.data?.getCompanyOrders?.items || [];
        projectItems.forEach((project) => {
          dispatch(setProjet({ id: project.projectId, ...project })); 
        });
        setProjects(projectItems);
      } catch (error) {
        console.error('Error fetching company projects:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProjects();
  }, [dispatch]); 
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProjects = projects.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = ( value) => {
    setCurrentPage(value);
  };


  const handleProjectClick = (project) => {
    if (project) {
      navigate(`/mes-demarches-create/${project.projectId}`); 
    } else {
      console.error('Project details not found.');
    }
  };
  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <Typography variant="h4" align="center" gutterBottom className="main-title h2-title">
          Mes démarches
        </Typography>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
            <ClipLoader size={50} color="#19AF78" />
          </div>
        ) : (
          <StyledTableContainer component={Paper}>
            <Table className="job-alert-table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Projet</StyledTableCell>
                  <StyledTableCell>Statut</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentProjects.map((project, index) => (
                  <JobAlertItem
                    key={project.projectId || index}
                    title={project.projectName}
                    category={getStatusTranslation(project.projectStatus) || 'Statut non défini'}
                    found="2"
                    time="Weekly"
                    projectId={project.projectId}
                    onClick={() => handleProjectClick(project)}
                  />
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}

        <Stack direction="row" justifyContent="center" mt={4}>
          <StyledButton component={Link} to="/statut-juridique">
            Créer une nouvelle société
          </StyledButton>
        </Stack>

        <PaginationComponent
          count={Math.ceil(projects.length / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default MesDemarches;
