
import React,{useState,useEffect} from 'react';
import DashboardHeader from '../../header';
import {Link} from "react-router-dom";
import { fetchDownloafile, getWebpackageOrder } from '../../../../../api/Services/webPackageService';
import { useParams } from 'react-router-dom'; 
import { useDispatch } from 'react-redux';
import { setProject } from '../../../../../redux/packageSlice';
import { ClipLoader } from 'react-spinners';

const Info = ({ setIsOpenSidebar }) => {
  const [isOpenGeneral, setIsOpenGeneral] = useState(false);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [isOpenLogo, setIsOpenLogo] = useState(false);
  const [projects, setProjects] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { projectId } = useParams(); 
  const [logoUrl, setLogoUrl] = useState("");
  const [galleryUrls, setGalleryUrls] = useState([]);

  const dispatch= useDispatch();


  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await getWebpackageOrder(projectId); 
        setProjects(response?.data?.getWebpackageOrder || {});
        const data = response?.data?.getWebpackageOrder || {};
        console.log("data dispatch",data);
        dispatch(setProject({ projectId, data })); 
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProject();
  }, [projectId ,dispatch]); 
  

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const filesToDownload = [];

        const logoKey = projects.logo?.logoToCreate?.s3ImageKey;
        if (logoKey) filesToDownload.push(logoKey);

        const galleryKeys = projects.website?.gallery?.map((image) => image.s3Key) || [];
        filesToDownload.push(...galleryKeys);

        const urls = await Promise.all(
          filesToDownload.map(async (key) => {
            const response = await fetchDownloafile(key);
            return response.data.generateWebpackageOrderS3PreSignedDownloadUrl.url;
          })
        );

        if (logoKey) setLogoUrl(urls[0]); 
        setGalleryUrls(urls.slice(logoKey ? 1 : 0)); 
      } catch (error) {
        console.error("Erreur lors du téléchargement des fichiers :", error);
      }
    };
  
    
    if (projects.logo || projects.website?.gallery?.length > 0) {
      fetchFiles();
    }
  }, [projects]);
  

  const toggleAccordionGeneral = () => setIsOpenGeneral(!isOpenGeneral);
  const toggleAccordionLogo = () => setIsOpenLogo(!isOpenLogo);
  const toggleAccordionGallery = () => setIsOpenGallery(!isOpenGallery);
  
 
  

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
    

        <h2 className="main-title text-center h2-titel"> Pharos WebPro &gt; {projects.projectName}</h2>
          <div className="bg-white card-box border-20 col-12">
          {isLoading ?( 
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                            <ClipLoader size={50} color="#19AF78" />
                      </div>
                     ) :(
                      <>
                    <h4 onClick={toggleAccordionGeneral} style={{ cursor: 'pointer' }}>
                      &gt;Informations générales
                    </h4>

                      {isOpenGeneral && (
                        <div className="mb-30">
                          <div className="mt-30">
                            <div className="row">
                            <div className="row mb-4">
                              <div className="col-md-6">
                                <label htmlFor="domainInput">Nom de domaine</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="domainInput"
                                  value={projects.domainName || ""}
                                  placeholder="Nom de domaine"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="companyInput">Nom société</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="companyInput"
                                  value={projects.website?.globalDetails?.companyName || ""}
                                  placeholder="Nom société"
                                  readOnly
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                              <div className="col-md-6">
                                <label htmlFor="addressInput">Adresse</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="addressInput"
                                  value={projects.website?.globalDetails?.address || ""}
                                  placeholder="Adresse"
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="phoneInput">Numéro de téléphone</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="phoneInput"
                                  value={projects.website?.globalDetails?.phoneNumber || ""}
                                  placeholder="Téléphone"
                                  readOnly
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-md-12">
                                  <h5>Horaires</h5>
                                  <div className="horaires-wrapper border p-3" style={{ borderRadius: '10px' }}>
                                    <div className="row mb-2">
                                      <div className="col"></div>
                                      <div className="col text-center">Début matinée</div>
                                      <div className="col text-center">Fin matinée</div>
                                      <div className="col text-center">Début après-midi</div>
                                      <div className="col text-center">Fin après-midi</div>
                                    </div>
                                    {['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh'].map((dayKey, index) => {
                                      const times = projects.website?.globalDetails?.days?.[dayKey] || [];
                                      const startMorning = times[0]?.split('-')[0] || '';
                                      const endMorning = times[0]?.split('-')[1] || '';
                                      const startAfternoon = times[1]?.split('-')[0] || '';
                                      const endAfternoon = times[1]?.split('-')[1] || '';
                                      
                                      const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

                                      return (
                                        <div className="row mb-2 align-items-center" key={dayKey}>
                                          <div className="col" style={{ width: '100px' }}>{daysOfWeek[index]}</div>
                                          <div className="col">
                                            <input
                                              type="text"
                                              className="form-control text-center"
                                              placeholder="Début matinée"
                                              value={startMorning}
                                              readOnly
                                            />
                                          </div>
                                          <div className="col">
                                            <input
                                              type="text"
                                              className="form-control text-center"
                                              placeholder="Fin matinée"
                                              value={endMorning}
                                              readOnly
                                            />
                                          </div>
                                          <div className="col">
                                            <input
                                              type="text"
                                              className="form-control text-center"
                                              placeholder="Début après-midi"
                                              value={startAfternoon}
                                              readOnly
                                            />
                                          </div>
                                          <div className="col">
                                            <input
                                              type="text"
                                              className="form-control text-center"
                                              placeholder="Fin après-midi"
                                              value={endAfternoon}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>

                            <div className="row">
                              <div className="col-md-12">
                                <h5>A propos</h5>
                                <textarea
                                  className="form-control"
                                  id="aboutInput"
                                  value={projects.website?.about?.paragraph || ""}
                                  placeholder="A propos"
                                  readOnly
                                />
                              </div>
                            </div>


                            
                            </div>
                          </div>
                        </div>
                      )}

                    <h4 onClick={toggleAccordionLogo} style={{ cursor: 'pointer' }}>
                      &gt;Logo
                    </h4>
                    {isOpenLogo && (
                      <div className="text-center mb-30">
                        {logoUrl.length > 0 ? (
                          <div className="d-flex justify-content-center mt-30">
                            <img
                              src={logoUrl}
                              alt="logo"
                              style={{ maxWidth: "200px", maxHeight: "200px", position: "center" }}
                            />
                          </div>
                        ) : (
                          <p>Chargement de l'aperçu du logo...</p>
                        )}
                      </div>
                    )}

                    <h4 onClick={toggleAccordionGallery} style={{ cursor: 'pointer' }}>
                      &gt;Galleries images
                    </h4>
                    {isOpenGallery && (
                      <div className="text-center mb-30">
                        {galleryUrls.length > 0 ? (
                          <div className="d-flex justify-content-center mt-30 row">
                            {galleryUrls.map((url, index) => (
                              <div className="col-md-3 mb-3" key={index}>
                                <div className="gallery-item gallery-item-card">
                                  <img
                                    src={url}
                                    alt={`Image ${index + 1}`}
                                    className="mx-auto d-block"
                                    width="100"
                                    style={{ paddingTop: "30%" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>Aucune image disponible pour cette galerie.</p>
                        )}
                      </div>
                    )}


                  <div className="d-flex justify-content-center mt-4">
                      <Link to={`/package-com1/${projectId}`} className="dash-btn-two tran3s me-3">Modifier le package</Link>
                    </div>
                    </>
                       )}
                  </div>
            </div>
    </div>
  );
};

export default Info;











// import React, { useEffect, useState } from 'react';
// import { useParams ,Link } from 'react-router-dom';
// import DashboardHeader from '../../header';
// import { getWebpackageOrder, upsertWebpackageOrder } from '../../../../../api/webPackage';
// import GeneralInfo from './ComponentInfo/GeneralInfo';
// import LogoSection from './ComponentInfo/LogoSection';
// import GallerySection from './ComponentInfo/GallerySection';

// const Info = ({ setIsOpenSidebar }) => {
//   const { projectId } = useParams();
//   const [projects, setProjects] = useState({});
//   const [loading, isLoading] = useState(true);
//   const [generalInfo, setGeneralInfo] = useState({});
//   const [logoInfo, setLogoInfo] = useState({});
//   const [galleryInfo, setGalleryInfo] = useState({});

//   useEffect(() => {
//     const fetchProject = async () => {
//       try {
//         const response = await getWebpackageOrder(projectId);
//         setProjects(response?.data?.getWebpackageOrder || {});
//       } catch (error) {
//         console.error('Error fetching projects:', error);
//       } finally {
//         isLoading(false);
//       }
//     };
//     fetchProject();
//   }, [projectId]);
// console.log("generalInfo",generalInfo);
//   const handleSubmit = async () => {
//     const input = {
//       ...generalInfo,
//       logo: logoInfo,
//       gallery: galleryInfo,
//     };

//     try {
//       await upsertWebpackageOrder(input);
//       console.log("Data submitted successfully!");
//     } catch (error) {
//       console.error("Submission error:", error);
//     }
//   };

//   return (
//     <div className="dashboard-body">
//       <div className="position-relative">
//         <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
//         <h2 className="main-title text-center h2-titel">
//           Pharos WebPro &gt; {projects?.projectName || 'Loading...'}
//         </h2>
//         <div className="bg-white card-box border-20 col-12">
//           <GeneralInfo onChange={(data) => setGeneralInfo(data)} />
//           <LogoSection onChange={(data) => setLogoInfo(data)} />
//           <GallerySection onChange={(data) => setGalleryInfo(data)} />
//           <div className="d-flex justify-content-center mt-4">
//             <Link to="/package-com1" className="dash-btn-two tran3s me-3">
//               Modifier le package
//             </Link>
//           </div>
//         </div>
       
//       </div>
//     </div>
//   );
// };


// export default Info;
