import React, { useEffect, useState } from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';
import { getAccountancyProjectConfigs } from '../../../../api/Services/invoiceService';
import PaginationComponent from '../../../pagination/pagination';
import { setProjet } from '../../../../redux/invoice';
import { useDispatch } from 'react-redux';




const FacturationIndex = ({ setIsOpenSidebar }) => {
    const dispatch = useDispatch();
  
  const [projects, setProjects] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const limit = 10;

  const fetchProjects = async (token) => {
    try {
      const response = await getAccountancyProjectConfigs(limit, token);
  
      setProjects(response.items);
      setNextToken(response.nextToken);
      setTotalCount(response.items.length);
  
      response.items.forEach((project) => {
        dispatch(setProjet({ id: project.projectId, ...project })); 
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des projets :', error);
    }
  };
  
  

  useEffect(() => {
    fetchProjects(nextToken);
  }, [nextToken]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    const tokenForPage = (page - 1) * limit; 
    fetchProjects(tokenForPage);
  };
  




  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className="main-title h2-titel">Pharos Compta (Ma facturation) &gt; Projet nom</h2>
        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
            <div className="col-12">
              {projects.map((project) => (
                <button
                  key={project.projectId}
                  type="button"
                  className="btn-eleven fw-500 tran3s d-block mt-20"
                >
                  <Link to={`/ma-facturation/${project.projectId}`}>
                    {project.projectName}
                  </Link>
                </button>
              ))}
              <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/creer-nouveau-projet">Créer un nouveau projet</Link>
              </button>
            </div>
          </div>
          <PaginationComponent
            count={Math.ceil(totalCount / limit)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
     
    </div>
  );
};

export default FacturationIndex;
