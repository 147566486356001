import React,{useState} from 'react';
import EmployAside from '../aside';
import EmailCalendar from './email-calendar';

const BoitesMessage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className='main-page-wrapper'>

      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
     

      
      <EmailCalendar setIsOpenSidebar={setIsOpenSidebar}/>
      
    </div>    
  );
};

export default BoitesMessage;