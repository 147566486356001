import { Accordion, AccordionDetails, AccordionSummary, Drawer, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { fetchGetCompanyProject, fetchUpSertCompany } from '../../../../../api/Services/companyService';
import AddAssocie from '../../../../../layouts/AddAssocie';
import DashboardHeader from '../../header';
import Swal from 'sweetalert2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import FicheDirigeant from './FicheDirigeant';
import FicheAssocies from './FicheAssocies';
import FicheEntreprise from './FicheEntreprise';
import FicheDocument from './FicheDocument';

const InformationsSociete = ({ setIsOpenSidebar }) => {
const {projectId}=useParams();

    const project = useSelector((state) => state.projet_communication?.projets?.[projectId]);
    console.log("project",project);
  
  const [companyData, setCompanyData] = useState(null);
  const [partnerData, setPartnerData] = useState([]);

  const [updatedProject, setupdatedProject] = useState({
    projectId: '',
    projectName: '',
    legalStatus: '',
    projectStatus: '',
    filesUploadedByAccountancyFirm: [],
  });

 
  const fetchProjectData = async () => {
    try {
      if (project?.projectId) {
        const result = await fetchGetCompanyProject(project?.projectId);
        if(result){
          setupdatedProject({
            projectId: result.projectId,
            projectName: result.projectName,
            legalStatus: result.legalStatus,
            projectStatus: result.projectStatus,
          });

        }
        if (result?.companyDetail) {
          setCompanyData({
            ...result.companyDetail,
            legalStatus: result.legalStatus, 
          });
  
          if(result?.companyPartners ){
            setPartnerData(result.companyPartners);
          }
        } else {
          setCompanyData({
            name: '',
            address: '',
            phone: '',
            email: '',
            socialCapitalAmount: '',
            socialCapitalCurrency: '',
            capitalStockAmount: '',
            capitalStockCurrency: '',
            legalStatus:'',
            
          });
          setPartnerData([
            {
                partnerId: '',
                name: '',
                isMainLeader: false,
                firstNames: [''],
                dateOfBirthday: '',
                placeOfBirthday: '',
                nationality: '',
                professionalSituation: '',
                socialSecurityNumber: '',
                spouseIdentity: {
                  name: '',
                  maidenName: '',
                  firstNames: [''],
                  dateOfBirthday: '',
                  placeOfBirthday: '',
                  nationality: '',

                },
                files: null,
             },
            ]);

        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };
  useEffect(() => {
    fetchProjectData();
  }, [project?.projectId]);

  const handleCompanyChange = (updatedData) => {
    setCompanyData(updatedData); 
  };
  const { legalStatus, ...companyDetailWithoutLegalStatus } = companyData || {};

  const handleSubmit = async () => {
    console.log("companydata",companyData);
    const input = {
      projectId: updatedProject?.projectId,
      legalStatus: companyData?.legalStatus|| "",
      companyDetail: companyDetailWithoutLegalStatus, 
      companyPartners: partnerData.map((partner, index) => ({
        partnerId: partner?.partnerId || `partnerId${index + 1}`,
        name: partner?.name,
        isMainLeader: index === 0,
        firstNames: Array.isArray(partner?.firstNames) ? partner?.firstNames : [partner?.firstNames],
        dateOfBirthday: partner?.dateOfBirthday,
        placeOfBirthday: partner?.placeOfBirthday,
        nationality: partner?.nationality,
        professionalSituation: partner?.professionalSituation,
        socialSecurityNumber: partner?.socialSecurityNumber,
        spouseIdentity: partner?.spouseIdentity || null,
      })),
    };
    console.log("input",input);

try {
      console.log("input", { input });
      const result = await fetchUpSertCompany(input);
    console.log("result",result);
      Swal.fire({
        icon: 'success',
        title: updatedProject?.projectId ? 'Mise à jour réussie' : 'Création réussie',
        text: 'Les données de l’entreprise ont été soumises avec succès.',
      })
       await fetchProjectData() ;
    
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: `Échec de la soumission des données : ${error.message}`,
      });
    }
  };
  
  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          className="main-title h2-title"
        >
          <span className="mb-3">Informations de la société </span>
          <br />
          <Typography
            variant="h4"
            color="textSecondary"
            sx={{ mt: 2 }}
          >
            {updatedProject?.projectName || ''}
          </Typography>

        </Typography>
        <div className="bg-white card-box border-20">
          <FicheDirigeant partnerData={partnerData}/>
         <hr className="mb-30 mt-30" />
          <FicheDocument/>
         <hr className="mb-30 mt-30" />
         <FicheEntreprise companyData={companyData} onChange={handleCompanyChange} />
          <hr className="mb-30 mt-30" />
         <FicheAssocies partnerData={partnerData}/>
          <div className="d-flex justify-content-center mt-4">
            <button className="dash-btn-two tran3s me-3" type="button" onClick={handleSubmit}>
              Mettre à jour
            </button>
          </div>
      
        </div>

     
      </div>
     
    </div>
  );
};

export default InformationsSociete;