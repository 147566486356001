export const statusTranslations = {
  PENDING_FOR_COMPLETING_CASE: "En cours de création",
  SUBMITTED_TO_ADMINISTRATION: "Soumis à l'administration",
  VALIDATED: "Validé",
  REJECTED: "Rejeté",
};


export const getStatusTranslation = (status) => {
    return statusTranslations[status] || "Statut non défini";
  };
  