import React, { useEffect, useState } from "react";
import DashboardHeader from "../header";
import CandidateItem from "./candidate-item";
import { fetchAchats } from "../../../../api/Services/achatService";
import { ClipLoader } from "react-spinners";
import { Alert } from "react-bootstrap";
import { fetchServicePice } from "../../../../api/Services/paymentSevice";

const MesAchatsIndex = ({ setIsOpenSidebar }) => {
  const [candidateData, setCandidateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const [purchasesResponse, pricesResponse] = await Promise.all([
          fetchAchats(),
          fetchServicePice(),
        ]);

        const purchases = purchasesResponse.data?.getUserPurchases?.items || [];
        const prices = pricesResponse?.items || [];

        const priceMap = prices.reduce((map, price) => {
          map[price.serviceType] = price;
          return map;
        }, {});

        const mappedData = purchases.map((purchase) => {
          const totalPrice = (purchase.services || []).reduce((sum, service) => {
            const servicePrice = priceMap[service]?.amount || 0;
            return sum + servicePrice;
          }, 0);

          return {
            id: purchase.projectId,
            name: purchase.projectName,
            date: purchase.creationDate,
            price: `${totalPrice} €`, 
            location: purchase.invoiceId,
          };
        });

        setCandidateData(mappedData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title m0 h2-titel">Mes achats</h2>
        </div>

        <div className="wrapper">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "150px" }}
            >
              <ClipLoader size={50} color="#19AF78" />
            </div>
          ) : candidateData.length > 0 ? (
            candidateData.map((item) => (
              <div key={item.id}>
                <CandidateItem item={item} />
              </div>
            ))
          ) : (
            <Alert severity="info">Aucun achat disponible.</Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default MesAchatsIndex;
