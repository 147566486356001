import axiosInstance from '../axiosConfig';

export const fetchAchats = async (input) => {
    try {
      const response = await axiosInstance.post('/graphql', {
        query: `
                 query getUserPurchases($limit: Int, $nextToken: String) {
                  getUserPurchases(limit: $limit, nextToken: $nextToken) {
                            items {
                                projectId
                                projectName
                                invoiceId
                                services
                                creationDate
                                updateDate
                            }
                    }
                }
          `
        ,
        variables: {
          input,
        }
  
      });
  
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("error reuerations des achats:", error);
      throw error;
    }
};
export const UserPurchaseS3 = async (projectId,invoiceId) => {
  try {
    const response = await axiosInstance.post('/graphql', {
      query: `
              mutation generateUserPurchaseS3PreSignedUploadUrl($projectId: String!, $invoiceId: String!) {
              generateUserPurchaseS3PreSignedUploadUrl(projectId: $projectId, invoiceId: $invoiceId) {
                  url
              }
          }
        `
      ,
      variables: {
        projectId,
        invoiceId
      }

    });

    console.log(response.data.data.generateUserPurchaseS3PreSignedUploadUrl);
    return response.data.data.generateUserPurchaseS3PreSignedUploadUrl;
  } catch (error) {
    console.error("error reuerations des achats:", error);
    throw error;
  }
};
