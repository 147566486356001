
const initialState = {
  projects: {},

  gallery: []
};

function packageReducer(state = initialState, action) {
  switch (action.type) {
   
   
      case 'SET_PROJECT': {
        const { projectId, data } = action.payload;
        const existingProject = state.projects?.[projectId] || {};
    
        return {
            ...state,
            projects: {
                ...state.projects,
                [projectId]: {
                    ...existingProject,
                    companyName: data?.companyName ?? existingProject.companyName ?? '',
                    domainName: data?.domainName ?? existingProject.domainName ?? '',
                    mailBoxEmailAddresses: data?.mailBoxEmailAddresses ?? existingProject.mailBoxEmailAddresses ?? [],
                    address: data?.website?.globalDetails?.address ?? existingProject.address ?? '',
                    phone: data?.website?.globalDetails?.phoneNumber ?? existingProject.phone ?? '',
                    hours: data?.website?.globalDetails?.days ?? existingProject.hours ?? {},
                     
                   socialNetworks: data?.website?.globalDetails?.socialNetworks ?? existingProject.socialNetworks ?? {},
                    services: {
                        text: data?.website?.services?.text ?? existingProject.services?.text ?? '',
                        items: data?.website?.services?.items ?? existingProject.services?.items ?? [],
                    },
                    paragraph: data?.website?.about?.paragraph ?? existingProject.paragraph ?? '',
                    fontFamily: data?.website?.globalDetails?.fontFamily ?? existingProject.fontFamily ?? '',
                    backgroundColor: data?.website?.globalDetails?.backgroundColor ?? existingProject.backgroundColor ?? '',
                    textColor: data?.website?.globalDetails?.textColor ?? existingProject.textColor ?? '',
                    icon: data?.logo?.logoToCreate?.icon ?? existingProject.icon ?? '',
                    colorForeground: data?.logo?.logoToCreate?.colorForeground ?? existingProject.colorForeground ?? '',
                    colorBackground: data?.logo?.logoToCreate?.colorBackground ?? existingProject.colorBackground ?? '',
                    title: data?.logo?.logoToCreate?.title ?? existingProject.title ?? '',
                    slogan: data?.logo?.logoToCreate?.slogan ?? existingProject.slogan ?? '',
                    fontTitle: data?.logo?.logoToCreate?.fontTitle ?? existingProject.fontTitle ?? '',
                    fontSlogan: data?.logo?.logoToCreate?.fontSlogan ?? existingProject.fontSlogan ?? '',
                    gallery: data?.website?.gallery ?? existingProject.gallery ?? [],
                    s3ImageKey: data?.logo?.logoToCreate?.s3ImageKey ?? existingProject.s3ImageKey ?? '',
                    imgMagasin: data?.website?.about?.s3Key ?? existingProject.imgMagasin ?? '',
                },
            },
        };
    }
    
      
    
    default:
      return state;
  }
}



export const setProject = ({ projectId, data }) => ({ type: 'SET_PROJECT',payload: {projectId,data, }});



export default packageReducer;
