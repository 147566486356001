import React, { useState } from "react";
import AccordionItem from "../Components/accordion/accordion-item";

const Feature = () => {
  const [openItems, setOpenItems] = useState([]); 

  const toggleItem = (id) => {
    setOpenItems((prevOpenItems) =>
      prevOpenItems.includes(id)
        ? prevOpenItems.filter((item) => item !== id) 
        : [...prevOpenItems, id] 
    );
  };

  return (
    <section className="text-feature-three position-relative background_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 order-lg-last ms-auto">
            <div className="wow fadeInRight">
              <div className="title-two">
                <h2 className="fw-600 color-blue text-center">Questions fréquentes</h2>
              </div>
              <div className="accordion accordion-style-one color-two mt-40" id="accordionOne">
                <AccordionItem
                  id="one"
                  isShow={openItems.includes("one")}
                  toggleItem={() => toggleItem("one")}
                  title="Puis-je utiliser Pharos si je ne connais rien au droit ou à l'entrepreneuriat ?"
                  desc="Bien sûr ! Notre solution est faite pour tous les entrepreneurs, quel que soit leur niveau d'expérience ou de connaissance. Notre équipe est là pour vous accompagner dès les débuts de votre projet. Nous proposons également un large éventail d'outils en ligne pour vous apporter les bonnes réponses aux questions critiques que les entrepreneurs se posent au début de leur projet."
                />
                <AccordionItem
                  id="two"
                  isShow={openItems.includes("two")}
                  toggleItem={() => toggleItem("two")}
                  title="Qui peut m'accompagner chez Pharos ?"
                  desc="Notre équipe de plus de 50 juristes diplômés en France est là pour vous aider de A à Z. Ils vérifient votre dossier, répondent à vos questions par téléphone ou par mail, et sont garants du succès de votre projet auprès du greffe. Et nous sommes plus de 120 à vos côtés au quotidien pour faire de votre projet un succès."
                />
                <AccordionItem
                  id="three"
                  isShow={openItems.includes("three")}
                  toggleItem={() => toggleItem("three")}
                  title="Comment nos tarifs peuvent-ils être aussi compétitifs ?"
                  desc="Grâce à une technologie novatrice de génération documentaire et de transmission des formalités, Pharos vous permet de réaliser vos démarches rapidement et à un prix compétitif, grâce à un parcours digitalisé, simple et intuitif. Pharos n'est pas un cabinet d'avocat. Bien sûr, si votre situation le requiert, nous saurons vous orienter vers l'un de nos partenaires, avocat ou expert-comptable."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
