import React from 'react';
import { Pagination, Stack } from '@mui/material';
import { styled } from '@mui/system';

const CustomPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    color: '#000',
  },
  '& .Mui-selected': {
    backgroundColor: 'rgb(25, 175, 120)!important',
    color: '#fff',
  },
  '& .MuiPaginationItem-ellipsis': {
    color: '#000',
  },
});

const PaginationComponent = ({ count, currentPage, onPageChange }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" mt={4}>
      <CustomPagination
        count={count}
        page={currentPage}
        onChange={onPageChange}
        shape="rounded"
      />
    </Stack>
  );
};

export default PaginationComponent;
