import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://zwf6mlywrrgpzanodo5a76omne.appsync-api.eu-west-1.amazonaws.com', 
});

const token = localStorage.getItem("authToken");
if (token) {
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
}

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
  
      localStorage.removeItem("authToken");
      
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
