import React, { useState } from "react";
import { Button, TextField, FormControl } from "@mui/material";
import ReactQuill from "react-quill"; 
import "react-quill/dist/quill.snow.css"; 
import Swal from 'sweetalert2';
import axios from 'axios';
import { getAttachmentUploadP, mutationSentEmail, saveDraftMails, uploadFilesToS3 } from "../../../../api/Services/emailService";

const ComposeEmailModal = ({ onClose, addressEmail }) => {

  const [emailData, setEmailData] = useState({
    sender: addressEmail, 
    destinationEmailAddresses: "", 
    subject: "",
    body: "",
  });
  const [attachments, setAttachments] = useState([]);
 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBodyChange = (value) => {
    setEmailData((prev) => ({ ...prev, body: value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments(files);
    setEmailData((prevData) => ({
      ...prevData,
      attachments: files,
    }));
  };
  
  

  const handleSend = async () => {
    if (!emailData.destinationEmailAddresses || !emailData.subject || !emailData.body) {
      Swal.fire({
        icon: 'warning',
        title: 'Champs manquants',
        text: 'Veuillez remplir tous les champs requis avant d\'envoyer l\'email.',
      });
      return;
    }
  
    try {
      
  
      const uploadedFileKeys = [];
  
      for (const file of attachments) {
        const uploadResponse = await uploadFilesToS3(file);
        console.log("Réponse du téléchargement :", uploadResponse);
  
        const fileKey = new URL(uploadResponse).pathname.substring(1); 
        uploadedFileKeys.push(fileKey);
      }
  
      console.log("Fichiers téléchargés avec succès :", uploadedFileKeys);
  
      const input = {
        sourceEmailAddress: emailData.sender,
        input: {
          body: emailData.body,
          subject: emailData.subject,
          destinationEmailAddresses: emailData.destinationEmailAddresses.split(","),
          attachments: uploadedFileKeys || [], 
        },
      };
      console.log("Input final pour l'envoi :", input);
  
      const response = await mutationSentEmail(input);
      console.log("Réponse de l'API :", response);
  
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Envoyé",
          text: "Email envoyé avec succès.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Erreur lors de l'envoi de l'email.",
      });
      console.error("Erreur lors de l'envoi de l'email :", error);
    }
    onClose();
  };
  
  
  
  const saveDraft = async () => {
    try {
    
      const input = {
        sourceEmailAddress: emailData.sender,
        input: {
          body: emailData.body,
          subject: emailData.subject,
          destinationEmailAddresses: emailData.destinationEmailAddresses.split(","),
          attachments: emailData.attachments || []
          // draftMailId: null,
        }
      };
  
      console.log("input", input);
  
      const response = await saveDraftMails(input);
      console.log("response", response);
    } catch (error) {
      console.error("Error lors de l'enregistrement de l'email comme brouillon", error);
    }
  };
  return (
    <div className="modal-overlay" style={{ overflowY: "auto"}}>
      <div className="modal-content">
        <header className="modal-header" >
          <h3 style={{ color: "green" }}>Envoyer un Email</h3>
          <button className="close-modal" onClick={onClose}>x</button>
        </header>
        <form>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Expéditeur"
              value={emailData.sender}
              disabled
              InputProps={{
                style: { backgroundColor: "#f5f5f5" },
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Destinataire(s)"
              name="destinationEmailAddresses"
              value={emailData.destinationEmailAddresses}
              onChange={handleInputChange}
              helperText="Séparez les adresses par des virgules"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Objet du mail"
              name="subject"
              value={emailData.subject}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl fullWidth margin="normal" sx={{ marginBottom: 3 }}>
          <label>Message</label>
            <ReactQuill
              value={emailData.body}
              onChange={handleBodyChange}
              theme="snow"
              style={{ height: '200px' }}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["bold", "italic", "underline"],
                  ["link"],
                  [{ align: [] }],
                  ["image", "video"],
                  [{ 'size': ['small', 'medium', 'large', 'huge'] }]
                ],
              }}
            />
          </FormControl>
          <div className="quill-file-attachments">
            <label>Fichiers joints :</label>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ marginBottom: "10px" }}
            />
            {attachments.length > 0 && (
              <ul>
                {attachments.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            )}
          </div>

          <div className="modal-footer">
            <Button
              variant="contained"
              style={{backgroundColor:"#20c997",marginRight: "10px"}}
              
              onClick={handleSend}
            >
              Envoyer
            </Button>
            <Button
              variant="contained"
              style={{backgroundColor:"#20c997",marginRight: "10px"}}
              onClick={saveDraft}
              >
                Sauvgarder comme bruillon
            </Button>
            <Button variant="outlined" color="error" onClick={onClose}>
              Annuler
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ComposeEmailModal;
