import React from "react";
import {Link} from "react-router-dom";

const JobAlertItem = ({
  title,
  location,
  category,
  duration,
  projectId,
  onClick 
}) => {
  return (
    <tr> 
      <td className='text-center'>
        <div>{title}</div>
        <div>{location}</div>
      </td>
      <td className='text-center'>
        <div className={`job-type fw-500 ${duration === "Part-Time" ? "part-time" : ""}`}>
        <Link
            to={`/mes-demarches-create/${projectId}`} 
            style={{ textDecoration: "none" }}
            onClick={(e) => e.stopPropagation()}
          >
            {category}
          </Link>
        </div>
      </td>
    </tr>
  );
};


export default JobAlertItem;
