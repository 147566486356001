import React, { useState } from "react"; 
import { Modal, Button, Form } from "react-bootstrap";
import {  uploadFileToS3 } from "../../../../api/Services/gedService";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { FaFolder } from "react-icons/fa";
import Swal from "sweetalert2";

const UploadFileModal = ({ showModal, handleCloseModal, projectId, folders,onUpdate }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [parentFolderId, setParentFolderId] = useState("root");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

 

  const handleUpload = async () => {
    if (!selectedFile) {
      return alert("Veuillez sélectionner un fichier à télécharger.");
    }
    try {
      setUploading(true);
      setUploadStatus("uploading");
      const success = await uploadFileToS3(projectId, parentFolderId, selectedFile);

      if (success) {
         Swal.fire({
               icon: 'success',
               title: ' Ajout du fichier ',
               text: 'le fichier a était ajouter avec success.',
             });
             handleCloseModal(true);
             if (onUpdate) onUpdate();
            setUploadStatus("success");
            setLoading(true);
            console.log("Upload réussi");
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: ' Ajout du fichier ',
        text: 'Erreur lors du chargement d ficier vouillez essayer plutart.',
      });
      handleCloseModal(true);
      console.error('Upload failed:', error);
      setUploadStatus("error");
      setLoading(true);
    } finally {
      setUploading(false);
    }
  };

  const renderFolderOptions = (folders, level = 0) => {
    const options = [];

    folders.forEach((folder) => {
      options.push({
        value: folder.id,
        label: (
          <div style={{ display: "flex", alignItems: "center", marginLeft: level * 10 }}>
            <FaFolder style={{ marginRight: "5px" }} />
            {folder.name}
          </div>
        ),
      });

      if (folder.subfolders && folder.subfolders.length > 0) {
        options.push(...renderFolderOptions(folder.subfolders, level + 1));
      }
    });

    return options;
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Charger un fichier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="fileUpload">
            <Form.Label>Sélectionnez un fichier :</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>

          <Form.Group controlId="parentFolder">
            <Form.Label>Dossier Parent :</Form.Label>
            <Select
              options={renderFolderOptions(folders)}
              value={renderFolderOptions(folders).find((opt) => opt.value === parentFolderId)}
              onChange={(selectedOption) => setParentFolderId(selectedOption?.value || "root")}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Fermer
        </Button>
        <Button
          variant="success"
          onClick={handleUpload}
          disabled={uploading || !selectedFile}
        >
          {uploading ? "Uploading..." : "Charger"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFileModal;
