
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, setPhone, setHours, setImgMagasin, setProject } from "../../../redux/packageSlice";
import { Grid, TextField, Typography, Box, Button } from '@mui/material';
import { fetchDownloafile, generateS3PreSignedUploadUrl } from "../../../api/Services/webPackageService";
import axios from "axios";

const CandidateV3Area = () => {
  const { projectId } = useParams();
    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
  
  const dispatch = useDispatch();
  const [file, setFile]=useState();
  const [uploadError,setUploadError]=useState();
  const [errors,setErrors]=useState({});
  const [imgUrl,setImgUrl]=useState();
  const address = projectData.address || "";
  const phone = projectData.phone || "";
  const hours = projectData.hours || {};
  const imgMagasin = projectData.imgMagasin;
 
  console.log(address, phone, hours);
  const [selectedAdress, setSelectedAdress] = useState(address || "");
  const [selectedPhone, setSelectedPhone] = useState(phone || "");
  const [selectedHours, setSelectedHours] = useState(hours || {});

  useEffect(() => {
    setSelectedAdress(address);
    setSelectedPhone(phone);
    setSelectedHours(hours);
  }, [address, phone, hours]);
 

  const validateHours = (value, sessionIndex, pairedValue) => {
    if (!value) return null;

    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; 
    if (!timeRegex.test(value)) return "Format invalide (HH:mm).";

    const [hours, minutes] = value.split(":").map(Number);
    const valueInMinutes = hours * 60 + minutes;

    if (pairedValue) {
        const [pairedHours, pairedMinutes] = pairedValue.split(":").map(Number);
        const pairedValueInMinutes = pairedHours * 60 + pairedMinutes;

        if (sessionIndex === 0 && valueInMinutes >= pairedValueInMinutes) 
            return "Début de la matinée doit être avant la fin.";
        if (sessionIndex === 2 && valueInMinutes >= pairedValueInMinutes) 
            return "Début de l'après-midi doit être avant la fin.";
    }

    return null;
};

const handleHourChange = (day, sessionIndex) => (event) => {
  const value = event.target.value;

  const currentDayHours = hours[day] || ["", ""];

  const pairedValue = sessionIndex === 0 
      ? currentDayHours[0]?.split("-")[1] 
      : sessionIndex === 2 
          ? currentDayHours[1]?.split("-")[1] 
          : null;

  const dayErrors = errors[day] || {};
  const errorMessage = validateHours(value, sessionIndex, pairedValue);

  setErrors((prev) => ({
      ...prev,
      [day]: {
          ...dayErrors,
          [sessionIndex]: errorMessage,
      },
  }));

  if (sessionIndex === 0) {
      currentDayHours[0] = `${value}-${currentDayHours[0]?.split("-")[1] || ""}`;
  } else if (sessionIndex === 1) {
      currentDayHours[0] = `${currentDayHours[0]?.split("-")[0] || ""}-${value}`;
  } else if (sessionIndex === 2) {
      currentDayHours[1] = `${value}-${currentDayHours[1]?.split("-")[1] || ""}`;
  } else if (sessionIndex === 3) {
      currentDayHours[1] = `${currentDayHours[1]?.split("-")[0] || ""}-${value}`;
  }

  // dispatch(setProject({
  //   projectId,
  //   data: { hours: { [day]: currentDayHours } },
  // }));
};

  // useEffect(() => {
  //   if (!address) dispatch(setProject({
  //     projectId,
  //     data: { address: '' }
  //   }));
  //   if (!phone) 
  //     dispatch(setProject({
  //     projectId,
  //     data: { phone: '' }
  //   }));
    
    
  //   if (!Object.keys(hours).length) {
  //     Object.keys(dayMap).forEach((day) => {
  //       dispatch(setProject({
  //         projectId,
  //         data: { hours: { [day]: ["", ""] } }
  //       }));
  //     });     
  //   }
  // }, [dispatch, address, phone, hours]);
  

const dayMap = {
  Lundi: 'first',
  Mardi: 'second',
  Mercredi: 'third',
  Jeudi: 'fourth',
  Vendredi: 'fifth',
  Samedi: 'sixth',
  Dimanche: 'seventh',
};

const handleAddressChange = (event) => {
  setSelectedAdress(event.target.value);
};

const handlePhoneChange = (event) => {
  const value = event.target.value.trim();
  setSelectedPhone(value);
  const error = validatePhone(value);
  setErrors((prev) => ({ ...prev, phone: error }));
};



const validatePhone = (phone) => {
  const phoneRegex = /^\+[1-9]\d{1,14}$/;
  return phoneRegex.test(phone) ? null : "Numéro invalide (format +[code_pays][numéro]).";
};


// const handlePhoneChange = (event) => {
//   const value = event.target.value.trim();
//   setSelectedPhone(value);

//   if (!validatePhone(value)) {
//     setErrors((prev) => ({ ...prev, phone: "Numéro de téléphone incorrect. Utilisez le format +[code_pays]numéro" }));
//   } else {
//     setErrors((prev) => ({ ...prev, phone: null }));
//   }

//   dispatch(setProject({
//     projectId,
//     data: { phone: value },
//   }));};


  
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadError(null); 
  };
 
  const uploadFileToS3 = async () => {
    
    try {
      const response = await generateS3PreSignedUploadUrl(projectId, file.name);
      console.log("Réponse de l'API pour l'URL pré-signée :", response);
  
      const { url, fields } = response;
      console.log("URL pré-signée :", url);
      console.log("Champs pré-signés :", fields);
  
      const fileData = new FormData();
      fileData.append("key", fields.key);
      fileData.append("policy", fields.policy);
      fileData.append("x-amz-algorithm", fields.xAmzAlgorithm);
      fileData.append("x-amz-credential", fields.xAmzCredential);
      fileData.append("x-amz-date", fields.xAmzDate);
      fileData.append("x-amz-security-token", fields.xAmzSecurityToken);
      fileData.append("x-amz-signature", fields.xAmzSignature);
      fileData.append("file", file);

      console.log("Données FormData envoyées dans la requête POST :");
      for (let pair of fileData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
              console.log("file", file.name);

      }
      console.log("Clé S3 stockée :", fields.key);
      dispatch(setProject({
        projectId,
        data: { imgMagasin: fields.key },
      }));
            console.log("Clé S3 stockée :", fields.key);
  
      const uploadResponse = await axios.post(url, fileData);
      console.log("Réponse du téléversement :", uploadResponse);

      console.log("Clé S3 stockée :", fields.key);
    } catch (error) {
      console.error("Erreur lors de la génération de l'URL pré-signée ou du téléversement :", error);
      if (error.response) {
        console.error("Réponse de l'API :", error.response.data);
        console.error("Statut de l'API :", error.response.status);
      } else {
        console.error("Erreur réseau ou problème de connectivité", error);
      }
    } 
  };
  console.log('rtg',imgMagasin);
  
  useEffect(() => {
    const getLogoUrl = async () => {
        if (imgMagasin) {
            try {
                const response = await fetchDownloafile(imgMagasin);
                setImgUrl(response.data.generateWebpackageOrderS3PreSignedDownloadUrl.url);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'URL pré-signée :", error);
            }
        }
    };

    getLogoUrl();
}, [file]);

const handleValidate = () => {
  const phoneError = validatePhone(selectedPhone);
  if (phoneError) {
    setErrors((prev) => ({ ...prev, phone: phoneError }));
    return;
  }


  dispatch(setProject(
    { projectId,
      data: {
        website: {
          globalDetails: {
            address: selectedAdress,
            phoneNumber: selectedPhone,
            days: selectedHours,
           
            
          }
        }
    }
  })) 
   
};
  
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative text-center pb-80 lg-pb-60" style={{ paddingTop: "135px", paddingBottom: "30px" }}>
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                  CRÉATION PACKAGE COMMERCIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="d-flex align-items-center justify-content-center">
              <Link to={`/package-com4N2/${projectId}`} className="btn btn-success">{"< Précédent"}</Link>
              <span className="mx-3">8/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Remplissez les informations générales</h4>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 mb-4">
              <div className="input-group-meta position-relative mb-25">
                <label htmlFor="address">Adresse si vous avez une boutique/magasin</label>
                <TextField
                  type="text"
                  id="address"
                  name="address"
                  value={selectedAdress}
                  onChange={handleAddressChange}
                  placeholder="Choisissez une Adresse"
                  className="form-control"
                  fullWidth
                />
              </div>
            </div>
            <div className="col-12 col-md-8 mb-4">
              <div className="input-group-meta position-relative mb-25">
                <label htmlFor="phone">Numéro de téléphone</label>
                <TextField
                  type="text"
                  id="phone"
                  name="phone"
                  value={selectedPhone}
                  onChange={handlePhoneChange}
                  placeholder="Entrez un Numéro de téléphone"
                  className="form-control"
                  error={!!errors.phone}
                  helperText={errors.phone}
                  fullWidth
                />
                
              </div>
            </div>
          
              <Box
                  sx={{
                    border: 1,
                    borderRadius: '10px',
                    borderColor: 'grey.300',
                    p: 3,
                  }}
                >
                <Grid container spacing={2} alignItems="center" className="mb-3">
                  <Grid item xs={12} md={2}>
                    <Typography variant="h6"></Typography>
                  </Grid>
                  {['Début matinée', 'Fin matinée', 'Début après-midi', 'Fin après-midi'].map((session, index) => (
                    <Grid item xs={12} md={2} key={index}>
                      <Typography variant="subtitle1" align="center">
                        {session}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>

                {Object.keys(dayMap).map((dayKey, dayIndex) => (
                  <div key={dayKey} className="mb-4">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={2}>
                        <Typography variant="h6">{dayKey}</Typography>
                      </Grid>

                      {['Début Matinée', 'Fin Matinée', 'Début Après-midi', 'Fin Après-midi'].map((_, sessionIndex) => (
                        <Grid item xs={12} md={2} key={sessionIndex}>
                          <TextField
                            type="text"
                            value={(hours[dayMap[dayKey]]?.[sessionIndex >= 2 ? 1 : 0] || "").split("-")[sessionIndex % 2] || ""}
                            onChange={handleHourChange(dayMap[dayKey], sessionIndex)}
                            placeholder="HH:mm" 
                            fullWidth
                            error={!!errors[dayMap[dayKey]]?.[sessionIndex]}
                            helperText={errors[dayMap[dayKey]]?.[sessionIndex]}
                            InputProps={{
                              style: { textAlign: 'center' }, 
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
              </Box>


            
             <div className="col-12 col-md-8 mb-4">
              <div className="input-group-meta position-relative mb-25">
              <label htmlFor="fileUpload">Chargez vos images</label>
              <input
                type="file"
                id="fileUpload"
                className="form-control"
                multiple 
                onChange={handleFileChange}
              />
              </div>
              </div>
              <div className="row justify-content-center mt-5">
                  <div className="col-12 col-md-8 text-center">
                    <button className="btn-eleven fw-500 tran3s d-block mt-20"        
                        
                        onClick={
                            uploadFileToS3
                          }
                    >
                      Upload 
                    </button>
                  </div>
            </div>
              <div className="text-center mb-30">
                        <div className="d-flex justify-content-center mt-30">
                        {imgUrl ? (
                          <img
                           src={imgUrl}
                            alt="logo"
                            style={{ maxWidth: "200px", maxHeight: "200px",position: "center" }}
                            />
                          ) : (
                             <p>Chargement de l'image...</p>
                            )}
                        </div>
                        
                      </div>
                    
            <div className="row justify-content-center mt-5">
              <div className="col-12 col-md-8 text-center">
                <Link to={`/package-com7/${projectId}`} className="btn btn-success"        
                  onClick={handleValidate}  
                 
                >
                  Validez
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
