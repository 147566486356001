import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Drawer, TextField
 } from '@mui/material';


const AddAssocie = ({ show, handleClose ,handleAddAssocie}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un associé</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
        <div className="row">
  
  
              <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                      <h4>Associé</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'firstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'name', placeholder: 'Nom' },
                        { label: 'Lieu de naissance', value: '', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Situation professionnelle', value: '', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                        { label: 'Numéro de sécurité sociale', value: '', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                        { label: 'Nationalité', value: '', id: 'nationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            placeholder={field.placeholder}
                           
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* Create Spouse */}
                <div className="col-md-6">
                  <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                      <h4>Conjoint(e)</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {[
                        { label: 'Prénom', value: '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'spouseName', placeholder: 'Nom' },
                        { label: 'Nom de jeune fille', value: '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                        { label: 'Lieu de naissance', value: '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Nationalité', value: '', id: 'spouseNationality', placeholder: 'Nationalité' },
                      ].map((field) => (
                        <div key={field.id} className="mb-4">
                          <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            placeholder={field.placeholder}
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
    
    
              </div>
                    </form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Link to="#"
                      style={{
                        backgroundColor: 'gray',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}onClick={handleClose}> Annuler</Link>
       
        
                    <Link
                      to="#"
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleAddAssocie}
                    >
                       Ajouter un associé
                    </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAssocie;
