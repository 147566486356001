import { Accordion, AccordionDetails, AccordionSummary, Drawer, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import AddAssocie from '../../../../../layouts/AddAssocie';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const FicheAssocies=({partnerData})=>{

    const [partners, setPartners]=useState([]);
      useEffect(() => {
              if (partnerData) {
                setPartners(partnerData);
              }
            }, [partnerData]);
    
      const handlePartnerChange = (e, partnerId) => {
        const { id, value } = e.target;
      
        setPartners((prevPartners) =>
          prevPartners.map((partner) => {
            if (partner.partnerId === partnerId) {
              if (id.startsWith('spouse')) {
                const spouseField = id.replace('spouse', ''); 
                const fieldKey = spouseField.charAt(0).toLowerCase() + spouseField.slice(1);
                return {
                  ...partner,
                  spouseIdentity: {
                    ...partner.spouseIdentity,
                    [fieldKey]: value,
                  },
                };
              }
              return { ...partner, [id]: value };
            }
            return partner;
          })
        );
      };
      const handleNewPartnerChange = (e) => {
        const { id, value } = e.target;
      
        setPartners((prev) => {
          const newPartner = prev.find((p) => p.partnerId === 'newPartner') || {
            partnerId: 'newPartner',
          };
      
          return [
            ...prev.filter((p) => p.partnerId !== 'newPartner'), 
            { ...newPartner, [id]: value },
          ];
        });
      };    
           const [isOpenGeneral, setIsOpenGeneral] = useState(false);
            const [isOpenEntreprise, setIsOpenEntreprise] = useState(false);
            const [isOpenDocument, setIsOpenDocument] = useState(false);
             const [isDrawerOpen, setDrawerOpen] = useState(false);
                const [showModal1, setShowModal1] = useState(false);
                    
            const toggleAccordionGeneral = () => setIsOpenGeneral(!isOpenGeneral);
            const toggleAccordionEntreprise = () => setIsOpenEntreprise(!isOpenEntreprise);
            const toggleAccordionDocument = () => setIsOpenDocument(!isOpenDocument);
            const [showModalAddAssocie, setShowModalAddAssocie] = useState(false);

            const handleOpenAddAssocie = () => setShowModalAddAssocie(true);
            const handleCloseAddAssocie = () => setShowModalAddAssocie(false);
            const handleAddAssocie = () => {

                handleCloseAddAssocie();
            };
            const handleIconClick1 = (iconClass) => {
                if (iconClass.includes("text-success")) {
                setDrawerOpen(true);
                }
            };
    return(
        <div className="row">
            <div style={{ cursor: "pointer" }} onClick={toggleAccordionGeneral}>
                 <AccordionSummary >
                <h4>
                   <ExpandMoreIcon style={{ marginRight: "8px" }} />
                    Fiche associés
                </h4>
                </AccordionSummary>
            </div>
            {isOpenGeneral && (
                <>
            {partners.some((partner) => !partner.isMainLeader) ? (
                partners
                .filter((partner) => !partner.isMainLeader)
                .map((leader) => (
                    <React.Fragment key={leader.partnerId}>
                    {/* Main Leader Section */}
                    <div className="col-md-6">
                        <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                        <AccordionSummary>
                            <h4>Associé1</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            {[
                            { label: 'Prénom', id: 'firstNames', placeholder: 'Prénom' },
                            { label: 'Nom', id: 'name', placeholder: 'Nom' },
                            { label: 'Lieu de naissance', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                            { label: 'Date de naissance', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                            { label: 'Situation professionnelle', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                            { label: 'Numéro de sécurité sociale', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                            { label: 'Nationalité', id: 'nationality', placeholder: 'Nationalité' },
                            ].map((field) => (
                            <div key={field.id} className="mb-4">
                                <TextField
                                label={field.label}
                                fullWidth
                                variant="outlined"
                                value={leader[field.id] || ''} 
                                placeholder={field.placeholder}
                                onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                                id={field.id}
                                type={field.type || 'text'}
                                InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                                />
                            </div>
                            ))}
                        </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* Spouse Section */}
                    <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                        <AccordionSummary>
                        <h4><ExpandMoreIcon style={{ marginRight: '8px' }} />Conjoint(e)</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                        {[
                            { label: 'Prénom', value: leader.spouseIdentity?.firstNames || '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                            { label: 'Nom', value: leader.spouseIdentity?.name || '', id: 'spouseName', placeholder: 'Nom' },
                            { label: 'Nom de jeune fille', value: leader.spouseIdentity?.maidenName || '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                            { label: 'Lieu de naissance', value: leader.spouseIdentity?.placeOfBirthday || '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                            { label: 'Date de naissance', value: leader.spouseIdentity?.dateOfBirthday || '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                            { label: 'Nationalité', value: leader.spouseIdentity?.nationality || '', id: 'spouseNationality', placeholder: 'Nationalité' },
                        ].map((field) => (
                            <div key={field.id} className="mb-4">
                            <TextField
                                label={field.label}
                                fullWidth
                                variant="outlined"
                                value={field.value} 
                                placeholder={field.placeholder}
                                onChange={(e) => handlePartnerChange(e, leader.partnerId)} 
                                id={field.id}
                                type={field.type || 'text'}
                                InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                            />
                            </div>
                        ))}
                        </AccordionDetails>
                    </Accordion>
                    </div>

                    </React.Fragment>
                ))
            ) : (
                <React.Fragment>
                {/* Create Main Leader */}
                <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                        <h4><ExpandMoreIcon style={{ marginRight: '8px' }} />Associé1</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[
                        { label: 'Prénom', value: '', id: 'firstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'name', placeholder: 'Nom' },
                        { label: 'Lieu de naissance', value: '', id: 'placeOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'dateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Situation professionnelle', value: '', id: 'professionalSituation', placeholder: 'Situation professionnelle' },
                        { label: 'Numéro de sécurité sociale', value: '', id: 'socialSecurityNumber', placeholder: 'Numéro de sécurité sociale' },
                        { label: 'Nationalité', value: '', id: 'nationality', placeholder: 'Nationalité' },
                        ].map((field) => (
                        <div key={field.id} className="mb-4">
                            <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                            />
                        </div>
                        ))}
                    </AccordionDetails>
                    </Accordion>
                </div>

                {/* Create Spouse */}
                <div className="col-md-6">
                    <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                    <AccordionSummary>
                        <h4><ExpandMoreIcon style={{ marginRight: '8px' }} />Conjoint(e)</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[
                        { label: 'Prénom', value: '', id: 'spouseFirstNames', placeholder: 'Prénom' },
                        { label: 'Nom', value: '', id: 'spouseName', placeholder: 'Nom' },
                        { label: 'Nom de jeune fille', value: '', id: 'spouseMaidenName', placeholder: 'Nom de jeune fille' },
                        { label: 'Lieu de naissance', value: '', id: 'spousePlaceOfBirthday', placeholder: 'Lieu de naissance' },
                        { label: 'Date de naissance', value: '', id: 'spouseDateOfBirthday', placeholder: 'Date de naissance', type: 'date' },
                        { label: 'Nationalité', value: '', id: 'spouseNationality', placeholder: 'Nationalité' },
                        ].map((field) => (
                        <div key={field.id} className="mb-4">
                            <TextField
                            label={field.label}
                            fullWidth
                            variant="outlined"
                            value={partners.find((p) => p.partnerId === 'newPartner')?.[field.id] || ''} 
                            placeholder={field.placeholder}
                            onChange={handleNewPartnerChange} 
                            id={field.id}
                            type={field.type || 'text'}
                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                            />
                        </div>
                        ))}
                    </AccordionDetails>
                    </Accordion>
                </div>
                </React.Fragment>
            )}

                <div className="table-responsive mt-20 mb-20" id="liste-table">
                <table className="table job-alert-table">
                    <tbody>
                    <tr>
                        <td className="text-left p-2">nom pièce 1</td>
                        <td className="text-center p-2">
                        <i
                            className="fas fa-file-alt text-success fs-4"
                            onClick={() => handleIconClick1('text-success')}
                        ></i>
                        </td>
                        <td className="text-center p-2">En attente/validé/rejeté</td>
                        <td className="text-center p-2">message du cabinet comptable</td>
                        <td className="text-center p-2">
                        <button onClick={() => setShowModal1(true)}>Charger</button>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left p-2">nom pièce 2</td>
                        <td className="text-center p-2">
                        <i className="fas fa-file-alt text-danger fs-4"></i>
                        </td>
                        <td className="text-center p-2">En attente/validé/rejeté</td>
                        <td className="text-center p-2">message du cabinet comptable</td>
                        <td className="text-center p-2">
                        <button onClick={() => setShowModal1(true)}>Charger</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>

                <div className="d-flex justify-content-start mt-4 mb-3">
                <button className="dash-btn-two tran3s me-3" onClick={handleOpenAddAssocie}>
                    Ajouter un associé
                </button>
                </div>

                <AddAssocie
                show={showModalAddAssocie}
                handleClose={() => setShowModalAddAssocie(false)}
                />
            </>
            )}
            </div>




    )
};
export default FicheAssocies;