import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../header';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAccountancyIssuerConfig, getLogoUpload, putAccountancyIssuerConfig, uploadLogoToS3,VisualiserLogo,telechargerLogo } from '../../../../../api/Services/invoiceService';
import Swal from 'sweetalert2';

const InformationGenerale = ({ setIsOpenSidebar }) => {
  const { projectId } = useParams();
  const projet = useSelector((state) => state.invoice.projets[projectId]);
   console.log("projet",projet);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [logoFile, setLogoFile] = useState(null); 
   const [logoUrl, setLogoUrl] = useState(''); // Stocker l'URL du logo



  const [configData, setConfigData] = useState({
    companyName: '',
    socialStatus: '',
    siret: '',
    contactName: '',
    address: '',
    phoneNumber: '',
    email: '',
    vatNumber: '',
    nafNumber: '',
    capitalStockAmount: '',
    capitalStockCurrency: '',
    iban: '',
    bicSwift: '',
    logoS3Key: '',
  });

  const fetchData = async () => {
    try {
      const data = await getAccountancyIssuerConfig(projectId);
      setConfigData(data || {});
      if (projectId) {
        const s3key = await VisualiserLogo(projectId);
        const url =  await telechargerLogo(s3key);
        console.log("url",url);
        setLogoUrl(url);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  useEffect(() => {
   
    fetchData();
  }, [projectId]);
 

  const handleChange = (field, value) => {
    setConfigData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleLogoChange = (e) => {
    setLogoFile(e.target.files[0]); 
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    
      if (!logoFile) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Veuillez sélectionner un fichier avant de téléverser.',
          confirmButtonText: 'OK',
        });
        return;
      }
  
      try {
        setIsSubmitting(true);
  
        const uploadData = await getLogoUpload(projectId, logoFile.name);
  
        const formData = new FormData();
        Object.keys(uploadData.fields).forEach((key) => {
          formData.append(key, uploadData.fields[key]);
        });
        formData.append('file', logoFile);
  
        await fetch(uploadData.url, {
          method: 'POST',
          body: formData,
        });
  
        await uploadLogoToS3(projectId, logoFile);
  
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: 'Logo téléversé avec succès.',
          confirmButtonText: 'OK',
        });
  
        fetchData(); 
      } catch (error) {
        console.error('Erreur lors du téléversement du logo:', error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors du téléversement du logo.',
          confirmButtonText: 'OK',
        });
      } finally {
        setIsSubmitting(false);
      }


try {
  await putAccountancyIssuerConfig(configData);

  Swal.fire({
    icon: 'success',
    title: 'Succès',
    text: 'Les informations ont été mises à jour avec succès !',
    confirmButtonText: 'OK',
  });

 await fetchData();
} catch (error) {
  console.error('Erreur lors de la mise à jour des données :', error);

  Swal.fire({
    icon: 'error',
    title: 'Erreur',
    text: 'Une erreur est survenue lors de la mise à jour.',
    confirmButtonText: 'OK',
  });
}
 finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h2 className=" d-flex flex-column align-items-center main-title h2-titel"> Ma facturation &gt; {projet.projectName} &gt;Information générale</h2>

        <div className="bg-white card-box border-20 col-12">
          <form >
          <h4 className="mb-4"><u>Votre logo</u></h4>
            <div className="d-flex flex-column align-items-center mb-40">
              <div className="user-avatar-setting d-flex flex-column align-items-center mb-30">
                <img src={logoUrl} alt="logo" width="100" />
                <div className="upload-btn position-relative dash-btn-two tran3s text-white me-3 mt-3">
                  Charger photo
                  <input type="file" id="uploadImg" name="uploadImg" onChange={handleLogoChange} />
                </div>
               
              </div>
            </div>


            <h4 className="mb-4" ><u>Vos Informations</u></h4>

           


            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="addressInput">Adresse</label>
                <input type="text" className="form-control" id="addressInput" placeholder="xxxxxx"
                value={configData.address || ''}
                onChange={(e) => handleChange('address', e.target.value)}

                 />
              </div>
              <div className="col-md-6">
                <label htmlFor="companyNameInput">Nom d'entreprise</label>
                <input type="text" className="form-control" id="companyNameInput" placeholder="xxxxxx"
                value={configData.companyName || ''}
                onChange={(e) => handleChange('companyName', e.target.value)}

                 />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="legalStatusInput">Statut légal </label>
                <select
                  className="form-control"
                  id="legalStatusInput"
                  value={configData.socialStatus || ''}
                  onChange={(e) => handleChange('socialStatus', e.target.value)}
                >
                  <option value="SAS">SAS</option>
                  <option value="SASU">SASU</option>
                  <option value="SARL">SARL</option>
                  <option value="EURL">EURL</option>
                  <option value="Association">Association</option>
                  <option value="Auto-Entreprise">Auto-Entreprise</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="contactNameInput">Nom du contact</label>
                <input
                  type="text"
                  className="form-control"
                  id="contactNameInput"
                  placeholder="Nom du contact"
                  value={configData.contactName || ''}  
                  onChange={(e) => handleChange('contactName', e.target.value)}  
                />
              </div>


            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="postalCodeInput">Code Postal</label>
                <input type="text" className="form-control" id="postalCodeInput" placeholder="xxxxxx"
                 />
              </div>
              <div className="col-md-6">
                <label htmlFor="cityInput">Ville</label>
                <input type="text" className="form-control" id="cityInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="emailInput">Adresse mail</label>
                <input type="text" className="form-control" id="emailInput" placeholder="xxxxxx" value={configData.email || ''} />
              </div>
              <div className="col-md-6">
                <label htmlFor="phoneInput">Numéro de téléphone</label>
                <input type="text" className="form-control" id="phoneInput" placeholder="xxxxxx" 
                 
                 value={configData.phoneNumber || ''}                   
                 onChange={(e) => handleChange('phoneNumber', e.target.value)}  
/>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="capitalInput">Capital social</label>
                <input
                  type="text"
                  className="form-control"
                  id="capitalInput"
                  value={configData.capitalStockAmount || ''}
                  placeholder="xxxxxx"
                  onChange={(e) => handleChange('capitalStockAmount', e.target.value)}  

                />
              </div>
              <div className="col-md-6">
                <label htmlFor="siretInput">Siret</label>
                <input type="text" className="form-control" id="siretInput" placeholder="xxxxxx" 
                value={configData.siret || ''}
                onChange={(e) => handleChange('siret', e.target.value)}  
/>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="nafInput">NAF</label>
                <input type="text" className="form-control" id="nafInput" placeholder="xxxxxx" value={configData.nafNumber || ''} 
                onChange={(e) => handleChange('nafNumber', e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="tvaInput">N° TVA Intracommunautaire</label>
                <input type="text" className="form-control" id="tvaInput" placeholder="xxxxxx" 
                value={configData.vatNumber || ''} 
                onChange={(e) => handleChange('vatNumber', e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-6">
                  <label htmlFor="capitalInput">Capital social</label>
                  <input
                    type="text"
                    className="form-control"
                    id="capitalInput"
                    value={configData.capitalStockAmount || ''}
                    onChange={(e) => handleChange('capitalStockAmount', e.target.value)}
                    placeholder="xxxxxx"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="capitalCurrencyInput">Devise du capital social</label>
                  <input
                    type="text"
                    className="form-control"
                    id="capitalCurrencyInput"
                    value={configData.capitalStockCurrency || ''}
                    onChange={(e) => handleChange('capitalStockCurrency', e.target.value)}
                    placeholder="xxxxx"
                  />
                </div>
            </div>


            <h4 className="mb-4" ><u>Vos conditions générales</u></h4>
            <div className="row mb-4">
              <div className="col-md-12">
                <textarea className="form-control" rows="3" name="description"></textarea>
              </div>
            </div>

            <h4 className="mb-4" ><u>Vos coordonnées bancaires</u></h4>
            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="accountNameInput">Nom du compte</label>
                <input type="text" className="form-control" id="accountNameInput" placeholder="xxxxxx" />
              </div>
              <div className="col-md-6">
                <label htmlFor="bankNameInput">Nom de la banque</label>
                <input type="text" className="form-control" id="bankNameInput" placeholder="xxxxxx"  />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="ibanInput">IBAN</label>
                <input type="text" className="form-control" id="ibanInput" placeholder="xxxxxx" 
                value={configData.iban || ''}
                onChange={(e) => handleChange('iban', e.target.value)}/>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label htmlFor="bicInput">BIC/SWIFT</label>
                <input type="text" className="form-control" id="bicInput" placeholder="xxxxxx"
                value={configData.bicSwift || ''} 
                onChange={(e) => handleChange('bicSwift', e.target.value)}/>
              </div>
              <div className="col-md-6">
                <label htmlFor="bankAddressInput">Adresse de la banque</label>
                <input type="text" className="form-control" id="bankAddressInput" placeholder="xxxxxx" />
              </div>
            </div>

            <div className="d-md-flex justify-content-md-center">
            <button
              type="submit"
              className="dash-btn-two tran3s me-3"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting ? 'En cours...' : 'Enregistrer'}
            </button>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InformationGenerale;
