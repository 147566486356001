import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import from react-router-dom
import { FaFileInvoice, FaFileAlt } from 'react-icons/fa';

const CandidateV3Area = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isDomiciliationSelected = location.state?.isDomiciliationSelected || false;
  const selectedService = location.state?.selectedService || 'Minimal'; 


  console.log("selectedService",selectedService);
  const handleNextStep = () => {
 console.log(isDomiciliationSelected);
    navigate('/create_soc4', { state: { isDomiciliationSelected ,selectedService } });
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION D’XXXXX</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <h2 className="fw-600">
              Ajoutez d’autres services Pharos pour une commande plus complète
            </h2>
            <br /> <br />
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/create_soc2">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">3/4</span>
            </div>
          </div>
          
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h3 className="fw-600">
              Cabinet Comptable
            </h3>
            <p className="fw-600 mt-4">
              Pharos vous met en lien avec ses cabinets expert-comptable<br />
              partenaires pour vous accompagner et conseiller<br />
              dans votre comptabilité.
            </p>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <button 
                type="button" 
                className="btn-eleven fw-500 tran3s d-block mt-2" 
                onClick={handleNextStep}
              >
                Etre mis en contact et poursuivre la commande →
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
