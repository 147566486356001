import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import "../../../css/dashboard.css";
import { getUserInfo } from "../../../api/Services/userService";


const nav_data = [
  {
    id: 1,
    icon: "/assets/dashboard/images/icon/icon_1.svg",
    icon_active: "/assets/dashboard/images/icon/icon_1_active.svg",
    link: "/dashboard",
    title: "Accueil",
  },
  {
    id: 2,
    icon: "/assets/dashboard/images/icon/icon_7.svg",
    icon_active: "/assets/dashboard/images/icon/icon_7_active.svg",
    link: "/mes-demarches",
    title: "Mes démarches",
  },
  {
    id: 3,
    icon: "/assets/dashboard/images/icon/icon_40.svg",
    icon_active: "/assets/dashboard/images/icon/icon_40_active.svg",
    link: "/ma-communication",
    title: "Ma communication",
  },
  {
    id: 4,
    icon: "/assets/dashboard/images/icon/icon_3.svg",
    icon_active: "/assets/dashboard/images/icon/icon_3_active.svg",
    link: "/ma-facturation-index",
    title: "Ma facturation",
    sous_menu: [
      {
        id: 1,
        icon: "/assets/dashboard/images/icon/icon_3.svg",
        icon_active: "/assets/dashboard/images/icon/icon_3_active.svg",
        link: "/ma-facturation-information-generale",
        title: "Informations générales",
      },
      {
        id: 2,
        icon: "/assets/dashboard/images/icon/icon_3.svg",
        icon_active: "/assets/dashboard/images/icon/icon_3_active.svg",
        link: "/ma-facturation-factures",
        title: "Factures",
      },
      {
        id: 3,
        icon: "/assets/dashboard/images/icon/icon_3.svg",
        icon_active: "/assets/dashboard/images/icon/icon_3_active.svg",
        link: "/ma-facturation-devis",
        title: "Devis",
      },
      {
        id: 4,
        icon: "/assets/dashboard/images/icon/icon_3.svg",
        icon_active: "/assets/dashboard/images/icon/icon_3_active.svg",
        link: "/ma-facturation-achats",
        title: "Achats",
      },
      {
        id: 5,
        icon: "/assets/dashboard/images/icon/icon_3.svg",
        icon_active: "/assets/dashboard/images/icon/icon_3_active.svg",
        link: "/ma-facturation-clients-fournisseurs",
        title: "Clients / fournisseurs",
      },
    ],
  },
  {
    id: 5,
    icon: "/assets/dashboard/images/icon/icon_39.svg",
    icon_active: "/assets/dashboard/images/icon/icon_39_active.svg",
    link: "/ged-index",
    title: "GED",
  },
  {
    id: 6,
    icon: "/assets/dashboard/images/icon/icon_4.svg",
    icon_active: "/assets/dashboard/images/icon/icon_4_active.svg",
    link: "/boites-mail",
    title: "Boites mail",
  },
  {
    id: 7,
    icon: "/assets/dashboard/images/icon/icon_6.svg",
    icon_active: "/assets/dashboard/images/icon/icon_6_active.svg",
    link: "/mes-achats",
    title: "Mes achats",
  },
  {
    id: 8,    
    icon: "/assets/dashboard/images/icon/icon_2.svg",
    icon_active: "/assets/dashboard/images/icon/icon_2_active.svg",
    link: "/profile",
    title: "Mes informations personnelles",
  },
];


const EmployAside = ({ isOpenSidebar, setIsOpenSidebar }) => {  
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(null);
  const [userInfo,setUserInfo]= useState();
  
  const handleMenuClick = (id) => {
    setOpenMenu(openMenu === id ? null : id);
  };
   useEffect(() => {
      const fetchUserInfo = async () => {
        try {
          const response = await getUserInfo();
          if (response) {
            setUserInfo({
              firstName: response.firstName || "",
              name: response.name || "",
              email: response.email || "",
              phoneNumber: response.phoneNumber || "",
            });
          }
        } catch (err) {
          console.error(
            "Erreur lors de la récupération des informations utilisateur :",
            err
          );
        } finally {
        }
      };
  
      fetchUserInfo();
    }, []);


  return (
    <>
      <aside className={`dash-aside-navbar ${isOpenSidebar ? 'show' : ''}`}>
        <div className="position-relative">
          <div className="logo text-md-center d-md-block d-flex align-items-center justify-content-between">
            <Link to="/">
              <img src="/assets/images/logo/logo-pharos.png" alt="logo"  />
            </Link>
            <button
              className="close-btn d-block d-md-none"
              onClick={() => setIsOpenSidebar(false)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
          <div className="user-data">
            <div className="user-avatar online position-relative rounded-circle">
            <img
          src="/assets/dashboard/images/user-profile.jpg"
          alt="avatar"
          className="lazy-img"
          style={{ width: '200px', height: 'auto' }}
        />

            </div>
            <div className="user-name-data">
              <button
                className="user-name dropdown-toggle"
                type="button"
                id="profile-dropdown"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
              {userInfo?.firstName}{' '}{userInfo?.name}
              </button>
              <ul className="dropdown-menu" aria-labelledby="profile-dropdown">
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to="/profile"
                  >
                    <img
                      src="/assets/dashboard/images/icon/icon_23.svg"
                      alt="icon"
                      className="lazy-img"
                    />
                    <span className="ms-2 ps-1">Profil</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to="#"
                  >
                    <img
                      src="/assets/dashboard/images/icon/icon_24.svg"
                      alt="icon"
                      className="lazy-img"
                    />
                    <span className="ms-2 ps-1">Paramètres</span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item d-flex align-items-center" to="#">
                    <img
                      src="/assets/dashboard/images/icon/icon_25.svg"
                      alt="icon"
                      className="lazy-img"
                    />
                    <span className="ms-2 ps-1">Notification</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <nav className="dasboard-main-nav">
            <ul className="style-none">
              {nav_data.map((m) => {
                const isActive = pathname === m.link;                
                return (
                  <React.Fragment key={m.id}>
                    <li onClick={() => setIsOpenSidebar(false)}>
                      <Link
                        to={m.link}
                        className={`d-flex w-100 align-items-center ${isActive ? 'active' : ''}`}
                      >
                        <img
                          src={isActive ? m.icon_active : m.icon}
                          alt=""
                          className="lazy-img"
                        />
                        <span>{m.title}</span>
                        {m.sous_menu && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleMenuClick(m.id);
                            }}
                            className="btn-toggle-submenu ms-3"
                          >
                            {openMenu === m.id ? '▲' : '▼'}
                          </button>
                        )}
                      </Link>
                    </li>
                    {m.sous_menu && openMenu === m.id && (
                      <ul className="submenu style-none ms-3">
                        {m.sous_menu.map((sub) => {
                          const isSubActive = pathname === sub.link;                          
                          return (
                            <li key={sub.id} onClick={() => setIsOpenSidebar(false)}>
                              <Link
                                to={sub.link}
                                className={`d-flex w-100 align-items-center ${isSubActive ? 'active' : ''}`}
                              >
                                <img
                                  src={sub.icon}
                                  alt=""
                                  className="lazy-img"
                                />
                                <span>{sub.title}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </React.Fragment>
                );
              })}
              
            </ul>
          </nav>          

          <Link to="#" className="d-flex w-100 align-items-center logout-btn " data-bs-toggle="modal" data-bs-target="#deleteModal">
            <img src="/assets/dashboard/images/icon/icon_9.svg" alt="icon" className="lazy-img mt-3" />
            <span className="mt-3">Déconnexion </span>
          </Link>
        </div>
      </aside>
         <div
            className="modal fade"
            id="deleteModal"
            tabIndex={-1}
            aria-hidden="true"
            >
           <div className="modal-dialog modal-fullscreen modal-dialog-centered">
              <div className="container">
                <div className="remove-account-popup text-center modal-content">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <img src="/assets/dashboard/images/icon/icon_22.svg" alt="icon" className="lazy-img m-auto" />
                  <h2>Êtes-vous sûr?</h2>
                  <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
                  <div className="button-group d-inline-flex justify-content-center align-items-center pt-15">
                    <a
                      href="/"
                      className="confirm-btn fw-500 tran3s me-3"
                      onClick={() => {
                        localStorage.removeItem("authToken"); 
                        window.location.href = "/"; 
                      }}
                    >
                      Oui
                    </a>
                    <button
                      type="button"
                      className="btn-close fw-500 ms-3"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>

        </div>
      {/* LogoutModal end */}
    </>
  );
};

export default EmployAside;
