import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setProject } from "../../../redux/packageSlice";
import {
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const CandidateV3Area = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projectData = useSelector((state) => state.package.projects?.[projectId] || {});

  const [selectedFont, setSelectedFont] = useState(projectData.fontFamily || "");

  useEffect(() => {
    setSelectedFont(projectData.fontFamily || "");
  }, [projectData.fontFamily]);

  const handleFontChange = (event) => {
    const font = event.target.value;
    setSelectedFont(font);

    // Mise à jour dans Redux
    dispatch(
      setProject({
        projectId,
        data: {
          website: {
            globalDetails: {
              fontFamily: font,
            },
          },
        },
      })
    );

  };

  return (
    <>
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        py: 8,
        textAlign: "center",
      }}
    >
      <Container>
        <Typography variant="h4" gutterBottom>
          CRÉATION PACKAGE COMMERCIAL
        </Typography>
      </Container>
    </Box>

    <Container sx={{ py: 5 }}>
      <Box textAlign="center" mb={4}>
        <Box display="inline-flex" alignItems="center">
        <div className="d-flex align-items-center justify-content-center" >
                          <Link to={`/package-com8/${projectId}`}>
                              <button type="button" className="btn btn-success">{"< Précédent"}</button>
                          </Link>
                      </div>
        
          <Typography variant="body1" sx={{ mx: 2 }}>
            12/15
          </Typography>
        </Box>
      </Box>

      <Typography  textAlign="center" gutterBottom>
        Choisissez la police de votre site
      </Typography>

      <Box sx={{ mt: 5 }}>
        <Box mb={4}>
          <Typography variant="body1" gutterBottom>
            Liste déroulante de polices
          </Typography>
          <Select
            id="font-select"
            value={selectedFont}
            onChange={handleFontChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">
              <em>-- Sélectionnez une police --</em>
            </MenuItem>
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Courier New">Courier New</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
            <MenuItem value="Verdana">Verdana</MenuItem>
          </Select>
        </Box>

        {selectedFont && (
          <Box
            sx={{
              fontFamily: `${selectedFont}, sans-serif`,
              p: 3,
              border: "1px solid #ddd",
              borderRadius: 1,
            }}
          >
           <Typography
          variant="h5"
          sx={{
            fontFamily: `${selectedFont}, sans-serif !important`,
          }}
        >
          Donnez un style unique à votre entreprise !
        </Typography>

          </Box>
        )}

<Box mt={4}>
<div className="col-12">
      <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">    
           <Link to={`/package-com10/${projectId}`} className="d-block">
                Validez
            </Link>
      </button>
</div>
         
        </Box>
      </Box>
    </Container>
  </>
  );
};

export default CandidateV3Area;
