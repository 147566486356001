import React, { useEffect, useState } from 'react';
import { FaFolder, FaTrash, FaEllipsisV, FaFile, FaSearch } from 'react-icons/fa';
import { Modal, Button, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DashboardHeader from '../header';
import { getWebpackageOrder } from '../../../../api/Services/webPackageService';
import { fetchSearchDocument, getDMSTrashDocuments } from '../../../../api/Services/gedService';
import DeplacerDocument from './deplacerDocument';
import { Pagination, styled } from '@mui/material';

const DocumentSigner = ({ setIsOpenSidebar }) => {
  const [folders, setFolders] = useState([]);
  const { projectId } = useParams();
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [searchResults, setSearchResults] = useState([]); 
  const [noResults, setNoResults] = useState(false);
  const handleShowDeplacer = (documentId) => { setSelectedDocumentId(documentId); setShowModalDeplcer(true)};
  const [showDeplacer,setShowModalDeplcer]=useState(false);
  const handleCloseDeplacer = () => setShowModalDeplcer(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [nextToken, setNextToken] = useState(null);



  const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-root': {
      color: '#000',
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(25, 175, 120)!important',
      color: '#fff',
    },
    '& .MuiPaginationItem-ellipsis': {
      color: '#000',
    },
  });
  
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    fetchFolders(newPage);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(folders.length / itemsPerPage);
  
  const fetchFolders = async (page = 1) => {
    try {
      setLoading(true);
      const response = await getDMSTrashDocuments(projectId, itemsPerPage, nextToken);
      const items = response?.items || [];
      const token = response?.nextToken || null;

      if (page === 1) {
        setFolders(items);
      } else {
        setFolders((prevFolders) => [...prevFolders, ...items]); 
      }

      setNextToken(token); 
    } catch (error) {
      console.error('Erreur lors de la récupération des dossiers:', error);
    } finally {
      setLoading(false);
    }
  };

  
    const fetchProject = async () => {
      try {
        const response = await getWebpackageOrder(projectId);
        setProject(response?.data?.getWebpackageOrder || {});
      } catch (error) {
        console.error('Erreur lors de la récupération du projet :', error);
      }
    };


    const paginateFolders = (folders, page, itemsPerPage) => {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return folders.slice(startIndex, endIndex);
    };
    const paginatedFolders = paginateFolders(folders, currentPage, itemsPerPage);

    
    const handleSearchDocument = async () => {
      try {
        if (!searchTerm.trim()) {
          setSearchResults([]);
          setNoResults(false);
          return;
        }
  
        setLoading(true);
        setNoResults(false);
  
        const results = await fetchSearchDocument(projectId, searchTerm);
        setSearchResults(results.items || []);
        setNoResults(results.items?.length === 0);
      } catch (error) {
        console.error('Erreur lors de la recherche :', error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchProject();
      fetchFolders();
    }, [projectId]);
  
   const handleFolderClick = (folder) => {
      setSelectedFolder(folder);
    };

  return (
    <div className="dashboard-body">
    <div className="position-relative w-100">
      <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
      <h2 className="d-flex flex-column align-items-center main-title h2-titel">
        GED &gt;{project.projectName}
      </h2>

      <div className="row" style={{ height: "90%" }}>
        <div className="sidebar col-4">
          <h2 className="d-flex flex-column main-title h2-titel">Pharos GED</h2>
          <ul className="folder-tree">
            <li>
              <Link to={`/ged/${project.projectId}`} style={{ textDecoration: "none" }}>Ma GED</Link>
            </li>
          </ul>
        </div>
        <div className="card content-area col-8">
          <div className="search-form mt-0 mb-20" style={{ position: "relative" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher dans ma GED..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchDocument();
                }
              }}
              style={{ paddingLeft: "35px" }}
            />
            <button
              onClick={handleSearchDocument}
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                padding: "0",
                cursor: "pointer",
              }}
            >
              <img
                src="/assets/dashboard/images/icon/icon_10.svg"
                alt="icon"
                className="lazy-img"
                style={{
                  height: "16px",
                }}
              />
            </button>
          </div>
          <div className="breadcrumb">Ma Corbeille</div>
          <div className="col-md-12">
            {searchResults.length > 0 ? (
              <div className="row">
                {searchResults.map((folder, index) => (
                  <div key={index} className="col-md-4 mb-3">
                    <Card>
                      <Card.Body className="position-relative">
                        <Card.Title className="fadeInUp">
                          {folder.documentType === 'FOLDER' ? <FaFolder /> : <FaFile />} {folder.documentName}
                        </Card.Title>
                      </Card.Body>
                      <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
                        <button
                          className="action-btn dropdown-toggle position-absolute"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ top: '0px', right: '0px' }}
                        >
                          <FaEllipsisV />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                          <li>
                            <Link className="dropdown-item" to="#"onClick={() => handleShowDeplacer(folder.id)}>
                              <img
                                src="https://img.icons8.com/ios-filled/24/000000/undo.png"
                                alt="Restaurer icon"
                                className="lazy-img"
                                style={{ width: '20px', height: '20px' }}
                              />
                              Restaurer
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            ) : noResults ? (
              <p>Aucun résultat trouvé.</p>
            ) : folders.length > 0 ? (
              <div className="row">
                {paginatedFolders.map((folder, index) => (
                  <div key={index} className="col-md-4 mb-3" onClick={() => handleFolderClick(folder)}>
                    <Card>
                      <Card.Body className="position-relative">
                        <Card.Title className="fadeInUp">
                          {folder.documentType === 'FOLDER' ? <FaFolder /> : <FaFile />} {folder.documentName}
                        </Card.Title>
                      </Card.Body>
                      <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
                        <button
                          className="action-btn dropdown-toggle position-absolute"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ top: '0px', right: '0px' }}
                        >
                          <FaEllipsisV />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                          <li>
                            <Link className="dropdown-item" to="#"onClick={() => handleShowDeplacer(folder.id)}>
                              <img
                                src="https://img.icons8.com/ios-filled/24/000000/undo.png"
                                alt="Restaurer icon"
                                className="lazy-img"
                                style={{ width: '20px', height: '20px' }}
                              />
                              Restaurer
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <CustomPagination
          count={totalPages}  
          page={currentPage}
          onChange={handlePageChange}
          shape="rounded"
        />
        

      </div>
    </div>
    {showDeplacer && (
      <DeplacerDocument
        showDeplacer={showDeplacer}
        handleCloseDeplacer={handleCloseDeplacer}
        projectId={projectId}
        documentId={selectedDocumentId}
      />
    )}

      <style>{`
        .dashboard-body {
          display: flex;
        }
        .sidebar {
          width: 250px;
          background-color: rgb(25, 175, 120) !important;
          color: white;
          padding: 20px;
        }
        .card {
          background-color: white;
        }
        .folder-tree {
          list-style: none;
          padding-left: 0;
        }
        .folder-tree li {
          margin-bottom: 10px;
        }
        .content-area {
          flex: 1;
          padding: 20px;
        }
        .search-bar input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }
        .actions {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;
        }
        .actions button {
          padding: 10px 15px;
          background-color: #ccc;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }
        .breadcrumb {
          margin-bottom: 20px;
        }
        .search-bar {
          display: flex;
          align-items: center;
        }
        .search-input {
          width: 100%;
          padding: 10px 30px 10px 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        @media (max-width: 768px) {
          .breadcrumb {
            font-size: 14px;
          }
          .search-bar input {
            padding: 8px;
          }
          .card-title {
            font-size: 16px;
          }
          .actions {
            flex-direction: column;
            gap: 5px;
          }
        }
      `}</style>
    </div>
  );
};

export default DocumentSigner;
