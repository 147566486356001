import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaInfoCircle } from 'react-icons/fa';
import { Grid, Paper, Typography, Button, Container } from '@mui/material';
import '../../../css/statut-juridique.css';
import { useDispatch } from "react-redux";
import { setStatut } from "../../../redux/statutJuridiqueSlice";

const companyData = [
  { id: 1, name: 'SAS' },  
  { id: 2, name: 'SASU' },
  { id: 3, name: 'EURL' },
  { id: 4, name: 'SARL' },
];

const CompanyCard = ({ item }) => {

  const dispatch = useDispatch();
  
    const handleSetStatut = () => {
      dispatch(setStatut(item.name)); 
      console.log("statut",setStatut(item.name));
    };
  return (
<Paper
  elevation={6}
  style={{
    padding: '15px', 
    boxShadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    border: '2px solid rgb(14, 151, 101)',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    width: '300px',  
  }}
  
>

      <div style={{ marginBottom: '10px' }}>
        <FaInfoCircle size={40} style={{ color: '#007bff' }} /> 
      </div>
      <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
        {item.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        {item.description}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <Link to="/simulator-info">
          <Button className="btn-success" style={{color:"white"}}  size="small"onClick={handleSetStatut}> 
            Simuler une {item.name}
          </Button>
        </Link>
      </div>
    </Paper>
  );
};

const StatutJuridiqueArea = ({ style_2 = false }) => {
  return (
    <div className="hero-banner-two position-relative background_section">
      <div className="container">
        <div className="position-relative pb-80 lg-pb-10" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Titre</h2>
              <p className="text-md mt-35 mb-50 lg-mb-30 fadeInUp text-center">sous-titre</p>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-two pt-20 xl-pt-0lg-pt-0 pb-5" style={{ backgroundColor: '#FFF' }}>
      <Container>
  <Grid container spacing={2} justifyContent="center">
    {companyData.map((item) => (
      <Grid
        key={item.id}
        item
        xs={12}  
        sm={6}    
        md={3}    
        className="col-lg-3 col-md-4 col-sm-6"
        style={{ display: 'flex', justifyContent: 'center' }} 
      >
        <CompanyCard item={item} />
      </Grid>
    ))}
  </Grid>
</Container>


        <br/><br/>
        <div className="blog-section-two px-4 pt-20 pb-20 background_section">
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp mt-5">
            <h3 className="title_staut fadeInUp mb-4">
              Vous ne savez pas encore quelle forme juridique choisir ? Laissez-vous guider par notre outil pour faire le bon choix !
            </h3>
          </div>
          <div className="card mx-auto mb-4" style={{ maxWidth: '400px' }}>
            <img src="/path-to-your-image.jpg" className="card-img-top" alt="Choisir la forme juridique" />
            <br />
            <div className="card-body text-center">
              <h5 className="card-title">Choisir la forme juridique de votre future entreprise</h5>
              <br />
              <button type="button" className="btn-eleven1 fw-500 tran3s d-block mt-20">
                <Link to="/activite-statut"> Lancer le simulateur</Link>
              </button>
            </div>
          </div>
          <h5 className="card-title text-center">Créer votre entreprise</h5>
          <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
            <Link to="/statut-juridique" className="d-block">
                 Démarrer
            </Link>
          </button>
        </div>
      </section>
    </div>
  );
};

export default StatutJuridiqueArea;
