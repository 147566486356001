"use client";
import React from "react";
import Footer from "../../../layouts/Footer";
import ScrollToTopButton from "../../Theme/Theme";
import Header from "../../../layouts/Header";
import { useSelector } from "react-redux";

const SimulatorInfo = () => {
  const staut_jur = useSelector((state) => state.statut_juridique.statut);
  console.log("Statut juridique sélectionné :", staut_jur);

  const simulateurModules = {
    SAS: "simulateur-assimilesalarie",
    SASU: "simulateur-assimilesalarie",
    EURL: "simulateur-eurl",
    SARL: "simulateur-eurl",
  };

  const module = simulateurModules[staut_jur] || "simulateur-embauche";

  const dataCouleur = "#19AF78";

  const iframeSrc = `https://mon-entreprise.urssaf.fr/iframes/${module}?lang=fr&data-couleur=${encodeURIComponent(
    dataCouleur
  )}`;



  return (
    <>
      <Header />

      <div className="hero-banner-two position-relative background_section">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingTop: "130px", paddingBottom: "20px" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                  Titre 
                </h2>
                <p className="text-md mt-35 mb-50 lg-mb-30 fadeInUp text-center">
                  sous-titre
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-two pt-30 xl-pt-30 lg-pt-30 pb-20">
        <div className="container">
          <div className="position-relative">
            <div className="row gx-xxl-5 justify-content-center">
              <div className="col-lg-12 col-md-12">
                <iframe
                  className="simulator-legend"
                  src={iframeSrc}
                  style={{
                    width: "100%",
                    height: "1000px",
                    border: "none",
                    display: "block",
                  }}
                  title={`Simulateur URSSAF pour ${staut_jur}`}
                  allow="clipboard-write; webshare"
                  allowFullScreen
                 
                  data-couleur={dataCouleur}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default SimulatorInfo;
