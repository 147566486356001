import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProject } from "../../../redux/packageSlice";

import { TextField, Button, Grid, Typography, Container, Box } from "@mui/material";

const CandidateV3Area = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();

    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
    const domainName = projectData?.domainName;
    const existingEmails = projectData?.mailBoxEmailAddresses || [];

    const [emails, setEmails] = useState(existingEmails.length > 0 ? existingEmails : [""]);
    const [errors, setErrors] = useState(existingEmails.map(() => ""));

    useEffect(() => {
        setEmails(existingEmails.length > 0 ? existingEmails : [""]);
        setErrors(existingEmails.map(() => ""));
    }, [existingEmails]);

    const handleAddEmail = () => {
        setEmails([...emails, ""]);
        setErrors([...errors, ""]);
    };

    const handleChangeEmail = (index, value) => {
        const updatedEmails = [...emails];
        const updatedErrors = [...errors];

        updatedEmails[index] = value;
        setEmails(updatedEmails);

        if (!value.endsWith(`@${domainName}`)) {
            updatedErrors[index] = `L'email doit se terminer par @${domainName}`;
        } else {
            updatedErrors[index] = "";
        }
        setErrors(updatedErrors);

        if (updatedErrors.every((error) => error === "")) {
            dispatch(
                setProject({
                    projectId,
                    data: {
                        ...projectData,
                        mailBoxEmailAddresses: updatedEmails,
                    },
                })
            );
        }
    };

    return (
        <>
            <Box className="hero-banner-two" sx={{ paddingTop: "135px", paddingBottom: "30px" }}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom>
                        CRÉATION PACKAGE COMMERCIAL
                    </Typography>
                </Container>
            </Box>

            <Box sx={{ padding: "30px 0" }}>
                <Container>
                    <Box mb={4} className="d-flex align-items-center justify-content-center">
                        <Link to={`/package-com1/${projectId}`}>
                            <button type="button" className="btn btn-success">
                                {"< Précédent"}
                            </button>
                        </Link>

                        <Typography component="span" sx={{ mx: 3 }}>
                            2/15
                        </Typography>
                    </Box>

                    <Typography variant="h6" align="center" gutterBottom>
                        Le package commercial comprend une adresse mail professionnelle
                        contact@{domainName}. Souhaitez-vous ajouter d’autres adresses ? (5 € HT / mois / adresse mail)
                    </Typography>

                    <Box component="form" maxWidth="sm" mx="auto">
                        <Grid container spacing={2}>
                            {emails.map((email, index) => (
                                <Grid item xs={12} key={index}>
                                    <TextField
                                        label={`Email ${index + 1}`}
                                        variant="outlined"
                                        placeholder="jean.dupont@example.com"
                                        value={email}
                                        onChange={(e) => handleChangeEmail(index, e.target.value)}
                                        fullWidth
                                        error={Boolean(errors[index])}
                                        helperText={errors[index]}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container justifyContent="space-between" spacing={2} sx={{ mt: 3 }}>
                            <Grid item xs={12} sm="auto">
                                <Button
                                 className="btn-eleven fw-500 tran3s d-block mt-20"
                                 variant="contained" onClick={handleAddEmail} fullWidth>
                                    Ajouter
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <Link to={`/package-com3/${projectId}`}>
                                    <Button
                                        className="btn-eleven fw-500 tran3s d-block mt-20"
                                        variant="contained"
                                        disabled={errors.some((error) => error !== "")}
                                        fullWidth
                                    >
                                        Valider
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <Link to={`/package-com3/${projectId}`}>
                                    <Button 
                                     className="btn-eleven fw-500 tran3s d-block mt-20"
                                     variant="contained" 
                                     fullWidth>
                                        Passer cette étape
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default CandidateV3Area;
