import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setParagraph, setProject } from "../../../redux/packageSlice";

const CandidateV3Area = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const projectData = useSelector((state) => state.package.projects?.[projectId] || {});
    const [selectedParagraph, setSelectedParagraph] = useState(projectData.paragraph);
    useEffect(() => {
            setSelectedParagraph(projectData.paragraph);
        
    }, [projectData]);

    const handleTextareaChange = (event) => {
        const newValue = event.target.value;
        setSelectedParagraph(newValue);
         dispatch(
            setProject({
              projectId,
              data: {
                website: {
                    about: {
                        paragraph: newValue
                    }
                }
            }
            })
          );
    };

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative pb-80 lg-pb-60 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="d-flex align-items-center justify-content-center">
                            <Link to={`/package-com8/${projectId}`}>
                                <button type="button" className="btn btn-success">{"< Précédent"}</button>
                            </Link>
                            <span className="mx-3">11/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">Ajoutez votre texte concernant votre activité</h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <div className="text-center">
                                <textarea
                                    value={selectedParagraph}
                                    onChange={handleTextareaChange}
                                    className="form-control"
                                    rows={4}
                                    placeholder="Entrez vos commentaires ici"
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to={`/package-com9/${projectId}`} className="d-block">
                                    Validez
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
