import { Accordion, AccordionDetails, AccordionSummary, Drawer, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const FicheEntreprise = ({ companyData, onChange }) => {
  const defaultFormData = {
    name: "",
    address: "",
    phone: "",
    email: "",
    socialCapitalAmount: "",
    socialCapitalCurrency: "",
    legalStatus: "",
  };
  
  const [formData, setFormData] = useState({ ...defaultFormData, ...companyData });
  
  useEffect(() => {
    if (companyData) {
      setFormData({ ...defaultFormData, ...companyData });
    }
  }, [companyData]);
  
  const handleCompanyChange = (e) => {
    const { id, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [id]: value || "", 
    }));
  
    onChange({
      ...formData,
      [id]: value || "",
    });
  };
  

  return (
    <div className="row">
      <div className="col-md-12">
        <Accordion style={{ boxShadow: 'none', border: 'none' }}>
          <AccordionSummary style={{ cursor: 'pointer' }}>
            <h4>
              <ExpandMoreIcon style={{ marginRight: '8px' }} />
              Fiche entreprise
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row mb-4">
              
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  label="Nom de l'entreprise"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: !!formData.name }}
                  id="name"
                  value={formData.name}
                  onChange={handleCompanyChange}
                />
              </div>
             
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  label="Adresse"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: !!formData.address }}
                  id="address"
                  value={formData.address}
                  onChange={handleCompanyChange}
                />
              </div>
            </div>
            <div className="row mb-4">
           
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  label="Numéro de téléphone"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: !!formData.phone }}
                  id="phone"
                  value={formData.phone}
                  onChange={handleCompanyChange}
                />
              </div>
              
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  label="Email"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: !!formData.email }}
                  id="email"
                  value={formData.email}
                  onChange={handleCompanyChange}
                />
              </div>
            </div>
            <div className="row mb-4">
              
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  fullWidth
                  label="Montant du capital social"
                  variant="outlined"
                  InputLabelProps={{ shrink: !!formData.socialCapitalAmount }}
                  id="socialCapitalAmount"
                  value={formData.socialCapitalAmount}
                  onChange={handleCompanyChange}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  label="Devise du capital social"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: !!formData.socialCapitalCurrency }}
                  id="socialCapitalCurrency"
                  value={formData.socialCapitalCurrency}
                  onChange={handleCompanyChange}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6 mb-3">
                <TextField
                  select
                  label="Statut legal du projet"
                  fullWidth
                  variant="outlined"
                  id="legalStatus"
                  value={formData.legalStatus}
                  onChange={(e) =>
                    handleCompanyChange({
                      target: { id: "legalStatus", value: e.target.value },
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="SAS">SAS</MenuItem>
                  <MenuItem value="SASU">SASU</MenuItem>
                  <MenuItem value="SARL">SARL</MenuItem>
                  <MenuItem value="EURL">EURL</MenuItem>
                  <MenuItem value="ASSOCIATION">Association</MenuItem>
                  <MenuItem value="AUTO_ENTREPRISE">Auto-Entreprise</MenuItem>
                </TextField>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FicheEntreprise ;